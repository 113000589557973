import { AuthProvider, useAuth } from './AuthContext'; // assuming it's in the root directory
import App from './App';
import React, { useEffect } from 'react';
import loggingService from './services/LoggingService';

// Component to initialize logging service with PocketBase
const LoggingInitializer = () => {
  const { pb, currentUserId, currentUserFullName, isAuthenticated } = useAuth();
  
  useEffect(() => {
    if (pb && isAuthenticated) {
      // Initialize logging service with PocketBase instance and user info
      loggingService.setPocketBaseInstance(pb, {
        id: currentUserId,
        name: currentUserFullName
      });
      console.info('LoggingService initialized with PocketBase instance');
    }
  }, [pb, currentUserId, currentUserFullName, isAuthenticated]);
  
  return null; // This component doesn't render anything
};

function AppWrapper() {
  return (
    <AuthProvider>
      <LoggingInitializer />
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
