"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var BaseIntentRecognizerSlovenian_1 = require("./BaseIntentRecognizerSlovenian");
var PersonalDataExtractor = /** @class */ (function (_super) {
    __extends(PersonalDataExtractor, _super);
    function PersonalDataExtractor() {
        var _this = _super.call(this) || this;
        _this.cityLowerCaseWords = ['vas', 'trg', 'mesto', 'selo', 'selca', 'na', 'pri'];
        _this.streetLowerCaseWords = __spreadArray(__spreadArray([], _this.cityLowerCaseWords, true), ['ulica', 'cesta', 'štradon'], false);
        _this.addIntent('firstName', ['ime', 'stranka'], function (value) { return value.trim(); });
        _this.addIntent('lastName', ['priimek'], function (value) { return value.trim(); });
        _this.addIntent('taxNumber', ['davčna', 'davčna številka'], function (value) {
            var match = value.match(/\d+/);
            return match ? match[0] : '';
        });
        _this.addIntent('streetName', ['naslov', 'živi na', 'živi v'], function (value) { return value.trim(); });
        _this.addIntent('city', ['kraj', 'pošta'], function (value) { return value.trim(); });
        _this.addIntent('phone', ['telefon', 'mobilna številka', 'mobilna', 'telefonska številka', 'telefonska', 'mobitel', 'gsm'], function (value) { return value.trim(); });
        _this.addIntent('email', ['email naslov', 'elektronska pošta', 'email', 'e-pošta', 'epošta'], function (value) { return value.trim(); });
        _this.addIntent('postalCode', ['poštna številka'], function (value) { return value.replace(/\D/g, ''); });
        _this.addIntent('next', ['naprej', 'naslednji']); // Presence-based intent
        _this.addIntent('isLegalEntity', ['pravna oseba']);
        _this.addIntent('isNotLegalEntity', ['fizična oseba']);
        _this.addIntent('note', ['opomba', 'opombe'], function (value) { return value.trim(); });
        return _this;
    }
    PersonalDataExtractor.prototype.recognize = function (input) {
        //console.log("Personal Data Extractor - recognizing");
        //return super.recognize(input);
        var results = _super.prototype.recognize.call(this, input);
        return this.postProcess(results);
    };
    PersonalDataExtractor.prototype.parseAddress = function (address) {
        var parts = address.split(/\s+/);
        var result = {};
        // Extract postal code and city
        var postalCodeIndex = parts.findIndex(function (part) { return /^\d{4}$/.test(part); });
        if (postalCodeIndex !== -1) {
            result.postalCode = parts[postalCodeIndex];
            result.city = parts.slice(postalCodeIndex + 1).join(' ');
            parts.splice(postalCodeIndex);
        }
        // Extract house number
        var houseNumberIndex = parts.findIndex(function (part) { return /^\d+[A-Za-z]?$/.test(part); });
        if (houseNumberIndex !== -1) {
            result.houseNumber = parts[houseNumberIndex];
            if (houseNumberIndex + 1 < parts.length && /^[A-Za-z]$/.test(parts[houseNumberIndex + 1])) {
                result.houseNumber += parts[houseNumberIndex + 1];
                parts.splice(houseNumberIndex, 2);
            }
            else {
                parts.splice(houseNumberIndex, 1);
            }
        }
        // The remaining parts form the street name
        result.streetName = parts.join(' ');
        return result;
    };
    PersonalDataExtractor.prototype.parseName = function (results) {
        var firstNameResult = results.find(function (r) { return r.intent === 'firstName'; });
        var lastNameResult = results.find(function (r) { return r.intent === 'lastName'; });
        if (firstNameResult && !lastNameResult) {
            var nameParts = firstNameResult.value.split(/\s+/);
            if (nameParts.length > 1) {
                return [
                    { intent: 'firstName', value: this.capitalizeWord(nameParts[0]) },
                    { intent: 'lastName', value: this.capitalizeAllWords(nameParts.slice(1).join(' ')) }
                ];
            }
            else {
                return [{ intent: 'firstName', value: this.capitalizeWord(firstNameResult.value) }];
            }
        }
        else if (firstNameResult && lastNameResult) {
            return [
                { intent: 'firstName', value: this.capitalizeAllWords(firstNameResult.value) },
                { intent: 'lastName', value: this.capitalizeAllWords(lastNameResult.value) }
            ];
        }
        return [];
    };
    PersonalDataExtractor.prototype.postProcess = function (results) {
        var processedResults = [];
        var isLegalEntityValue = null;
        // Handle name parsing
        var nameParts = this.parseName(results);
        processedResults.push.apply(processedResults, nameParts);
        for (var _i = 0, results_1 = results; _i < results_1.length; _i++) {
            var result = results_1[_i];
            switch (result.intent) {
                case 'firstName':
                case 'lastName':
                    //processedResults.push({ ...result, value: this.capitalizeAllWords(result.value) });
                    break;
                case 'city':
                    var _a = this.extractPostalCodeAndCity(result.value), postalCode = _a[0], city = _a[1];
                    if (postalCode) {
                        processedResults.push({ intent: 'postalCode', value: postalCode });
                        if (city) { // Add this null check
                            processedResults.push({ intent: 'city', value: this.capitalizeWithExceptions(city, this.cityLowerCaseWords) });
                        }
                    }
                    else {
                        processedResults.push(__assign(__assign({}, result), { value: this.capitalizeWithExceptions(result.value, this.cityLowerCaseWords) }));
                    }
                    break;
                case 'streetName':
                    var addressParts = this.parseAddress(result.value);
                    for (var _b = 0, _c = Object.entries(addressParts); _b < _c.length; _b++) {
                        var _d = _c[_b], intent = _d[0], value = _d[1];
                        if (intent === 'streetName' || intent === 'city') {
                            processedResults.push({
                                intent: intent,
                                value: this.capitalizeWithExceptions(value, this.streetLowerCaseWords)
                            });
                        }
                        else {
                            processedResults.push({ intent: intent, value: value });
                        }
                    }
                    break;
                case 'isNotLegalEntity':
                    isLegalEntityValue = 'false';
                    break;
                case 'isLegalEntity':
                    isLegalEntityValue = 'true';
                    break;
                default:
                    processedResults.push(result);
            }
        }
        if (isLegalEntityValue !== null) {
            processedResults.push({ intent: 'isLegalEntity', value: isLegalEntityValue });
        }
        return processedResults;
    };
    PersonalDataExtractor.prototype.capitalizeAllWords = function (str) {
        return str.replace(/\S+/g, this.capitalizeWord);
    };
    PersonalDataExtractor.prototype.capitalizeWithExceptions = function (str, exceptions) {
        var _this = this;
        var words = str.toLowerCase().split(/\s+/);
        return words.map(function (word, index) {
            return index === 0 || !exceptions.includes(word) ? _this.capitalizeWord(word) : word;
        }).join(' ');
    };
    PersonalDataExtractor.prototype.capitalizeWord = function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };
    PersonalDataExtractor.prototype.extractPostalCodeAndCity = function (value) {
        var match = value.match(/^(\d{4})\s+(.+)$/);
        if (match) {
            return [match[1], match[2]];
        }
        return [null, value];
    };
    return PersonalDataExtractor;
}(BaseIntentRecognizerSlovenian_1.BaseIntentRecognizer));
var personalExtractor = new PersonalDataExtractor();
var testCases = [];
/**
var testCases = [
    "ime je janez novak pravna oseba",
    "ime Ana Kovač davčna 87654321 naslov Cankarjeva 5 kraj Maribor mobilna številka 031 987 654 fizična oseba",
    "moje ime Mirko Šlobar davčna 11122233 živim na Slovenska cesta 1 v Ljubljani telefonska 040 123 456",
    "ime Maja Horvat naslov globočice pri kostanjevici 9 B 8311 kostanjevica na krki email maja.horvat@example.com naslednji pravna oseba ime Maja 2 ime Maja 3 fizična oseba",
    "ime Peter mesto Ljubljana ime Janez naslov globočice pri kostanjevici 9b 8311 kostanjevica na krki pravna oseba oziroma ne fizična oseba",
    "ime janez damjan davčna številka je 12340000",
    "ime janez damjan priimek novak davčna številka je 12340000",
    "na",
    "",
    "ime janez damjan priimek novak davčna številka je 12340000 ime mihael",
    "ime janez priimek novak davčna številka je 12340000 ime mihael damjan"
];
*/
console.log("Personal Data Extraction:");
testCases.forEach(function (testCase) {
    var results = personalExtractor.recognize(testCase);
    console.log("Input: \"".concat(testCase, "\""));
    console.log('Results:', results);
    console.log('---');
});
exports["default"] = PersonalDataExtractor;
