/**
 * LoggingService.js
 * Service for capturing console logs and sending them to a specified email or saving to PocketBase
 * Provides configurable logging levels and enhanced debugging capabilities
 */

class LoggingService {
  constructor() {
    this.logs = [];
    this.maxLogs = 1000; // Maximum number of logs to store
    this.hasErrors = false;
    this.emailEndpoint = '/api/send-logs'; // This would be implemented on the backend
    this.recipientEmail = 'david@starina.si';
    this.pocketBaseEnabled = true; // Enable saving logs to PocketBase
    this.pocketBaseCollection = 'logs'; // PocketBase collection name for logs
    this.pocketBaseInstance = null; // Will be set when available
    this.userInfo = { id: null, name: null }; // Will store user info when available
    this.appInfo = { version: '1.0.0', environment: process.env.NODE_ENV || 'development' };
    this.pendingLogs = []; // Store logs that couldn't be sent immediately
    
    // Configurable log levels
    this.logLevels = {
      error: 0,
      warn: 1,
      info: 2,
      log: 3,
      debug: 4
    };
    
    // Current log level - only logs of this level and below will be displayed
    // Default: show everything except debug in production, show everything in development
    //this.currentLogLevel = process.env.NODE_ENV === 'production' ? this.logLevels.info : this.logLevels.debug;
    this.currentLogLevel = this.logLevels.debug;
    
    // Initialize the service
    this.init();
    
    // Log service initialization
    this.originalConsole.info('LoggingService initialized with log level:', 
      Object.keys(this.logLevels).find(key => this.logLevels[key] === this.currentLogLevel));
      
    // Try to get PocketBase instance from window if available
    this.tryGetPocketBaseInstance();
  }

  init() {
    // Store original console methods
    this.originalConsole = {
      log: console.log,
      info: console.info,
      warn: console.warn,
      error: console.error,
      debug: console.debug
    };

    // Override console methods to capture logs
    console.log = (...args) => this.captureLog('log', ...args);
    console.info = (...args) => this.captureLog('info', ...args);
    console.warn = (...args) => this.captureLog('warn', ...args);
    console.error = (...args) => this.captureLog('error', ...args);
    console.debug = (...args) => this.captureLog('debug', ...args);

    // Set up global error handler
    window.addEventListener('error', (event) => {
      this.captureLog('error', 'Uncaught error:', event.error);
      this.hasErrors = true;
      this.sendLogsAutomatically();
    });

    // Set up unhandled promise rejection handler
    window.addEventListener('unhandledrejection', (event) => {
      this.captureLog('error', 'Unhandled promise rejection:', event.reason);
      this.hasErrors = true;
      this.sendLogsAutomatically();
    });
  }

  captureLog(level, ...args) {
    // Check if this log should be displayed based on current log level
    if (this.logLevels[level] <= this.currentLogLevel) {
      // Call the original console method
      this.originalConsole[level](...args);
    }

    // Format the log entry
    const timestamp = new Date().toISOString();
    
    // Add execution context information
    const stack = new Error().stack;
    const stackLines = stack.split('\n');
    // Get the caller info (skip the first two lines which are this function and its caller)
    const callerInfo = stackLines.length > 2 ? stackLines[2].trim() : 'unknown';
    const callerMatch = callerInfo.match(/at\s+(.*?)\s+\((.*):(\d+):(\d+)\)/i);
    const caller = callerMatch ? {
      function: callerMatch[1],
      file: callerMatch[2].split('/').pop(),
      line: callerMatch[3]
    } : { function: 'unknown', file: 'unknown', line: 'unknown' };
    
    const formattedArgs = args.map(arg => {
      if (arg instanceof Error) {
        return `${arg.name}: ${arg.message}\n${arg.stack}`;
      } else if (typeof arg === 'object') {
        try {
          return JSON.stringify(arg, null, 2);
        } catch (e) {
          return String(arg);
        }
      } else {
        return String(arg);
      }
    }).join(' ');

    // Create log entry with enhanced context
    const logEntry = {
      timestamp,
      level,
      message: formattedArgs,
      source: `${caller.file}:${caller.line} (${caller.function})`
    };

    // Add to logs array
    this.logs.push(logEntry);

    // Trim logs if they exceed the maximum
    if (this.logs.length > this.maxLogs) {
      this.logs = this.logs.slice(-this.maxLogs);
    }

    // Set error flag if this is an error log
    if (level === 'error') {
      this.hasErrors = true;
    }
  }

  getLogs() {
    return this.logs;
  }

  clearLogs() {
    this.logs = [];
    this.hasErrors = false;
  }

  async sendLogsAutomatically() {
    // Only send logs automatically if there are errors
    if (this.hasErrors) {
      if (this.pocketBaseEnabled) {
        await this.saveToPocketBase();
      } else {
        await this.sendLogs();
      }
    }
  }

  async sendLogs() {
    try {
      // Format logs for email
      const formattedLogs = this.formatLogsForEmail();
      
      // Send logs to backend
      const response = await fetch(this.emailEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.recipientEmail,
          logs: formattedLogs,
          subject: `Agentron Console Logs - ${this.hasErrors ? 'ERRORS DETECTED' : 'Manual Send'}`
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to send logs: ${response.statusText}`);
      }

      return true;
    } catch (error) {
      // Use the original console to avoid infinite loops
      this.originalConsole.error('Failed to send logs:', error);
      // Try to save to PocketBase as fallback
      if (this.pocketBaseEnabled) {
        this.saveToPocketBase().catch(e => {
          this.originalConsole.error('Failed to save logs to PocketBase as fallback:', e);
        });
      }
      return false;
    }
  }

  formatLogsForEmail() {
    // Create a text representation of the logs with enhanced context
    return this.logs.map(log => {
      return `[${log.timestamp}] [${log.level.toUpperCase()}] [${log.source || 'unknown'}] ${log.message}`;
    }).join('\n');
  }

  downloadLogs() {
    const formattedLogs = this.formatLogsForEmail();
    const blob = new Blob([formattedLogs], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `agentron-logs-${new Date().toISOString()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    this.originalConsole.info('Logs downloaded successfully');
  }

  /**
   * Set the current log level
   * @param {string} level - One of 'error', 'warn', 'info', 'log', 'debug'
   */
  setLogLevel(level) {
    if (this.logLevels.hasOwnProperty(level)) {
      this.currentLogLevel = this.logLevels[level];
      this.originalConsole.info('Log level set to:', level);
    } else {
      this.originalConsole.error('Invalid log level:', level);
    }
  }

  /**
   * Get the current log level
   * @returns {string} The current log level
   */
  getLogLevel() {
    return Object.keys(this.logLevels).find(key => this.logLevels[key] === this.currentLogLevel);
  }

  /**
   * Log application performance metrics
   * @param {string} label - Label for the performance measurement
   */
  logPerformance(label) {
    if (window.performance && window.performance.now) {
      const performanceNow = window.performance.now();
      this.captureLog('info', `PERFORMANCE [${label}]: ${performanceNow.toFixed(2)}ms`);
      return performanceNow;
    }
    return null;
  }

  /**
   * Set the PocketBase instance to use for saving logs
   * @param {Object} pbInstance - PocketBase instance
   * @param {Object} userInfo - User information { id, name }
   */
  setPocketBaseInstance(pbInstance, userInfo = null) {
    this.pocketBaseInstance = pbInstance;
    if (userInfo) {
      this.userInfo = userInfo;
    }
    // Try to send any pending logs
    this.processPendingLogs();
  }

  /**
   * Try to get PocketBase instance from AuthContext if available
   */
  tryGetPocketBaseInstance() {
    // Check if we're in a browser environment
    if (typeof window !== 'undefined') {
      // Wait for the app to be fully initialized
      setTimeout(() => {
        // Try to find PocketBase instance in the global scope
        if (window.pb) {
          this.setPocketBaseInstance(window.pb);
        }
      }, 2000); // Wait 2 seconds for app initialization
    }
  }

  /**
   * Ensure logs don't exceed the character limit
   * @param {Array} logs - Array of log objects
   * @param {number} maxChars - Maximum number of characters allowed
   * @returns {Array} - Truncated array of log objects
   */
  ensureLogsUnderCharLimit(logs, maxChars = 5000) {
    if (!logs || logs.length === 0) return logs;
    
    // First try: Stringify to check total size
    let logsString = JSON.stringify(logs);
    
    // If already under limit, return as is
    if (logsString.length <= maxChars) return logs;
    
    // Strategy 1: Truncate individual messages if they're too long
    const truncatedLogs = logs.map(log => {
      // Create a copy of the log
      const newLog = { ...log };
      
      // If message is very long, truncate it
      if (newLog.message && newLog.message.length > 500) {
        newLog.message = newLog.message.substring(0, 497) + '...';
      }
      
      return newLog;
    });
    
    // Check if truncating messages was enough
    logsString = JSON.stringify(truncatedLogs);
    if (logsString.length <= maxChars) return truncatedLogs;
    
    // Strategy 2: Keep only the most recent and important logs
    // Sort by importance (errors first, then warnings, then others)
    // and then by timestamp (most recent first)
    const sortedLogs = [...truncatedLogs].sort((a, b) => {
      // First sort by level importance
      const levelA = a.level === 'error' ? 0 : a.level === 'warn' ? 1 : 2;
      const levelB = b.level === 'error' ? 0 : b.level === 'warn' ? 1 : 2;
      
      if (levelA !== levelB) return levelA - levelB;
      
      // Then sort by timestamp (most recent first)
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
    
    // Keep reducing the number of logs until we're under the limit
    let reducedLogs = sortedLogs;
    while (JSON.stringify(reducedLogs).length > maxChars && reducedLogs.length > 1) {
      // Remove the least important logs (from the end of the sorted array)
      reducedLogs = reducedLogs.slice(0, reducedLogs.length - 1);
    }
    
    // If we still exceed the limit with just one log, truncate it further
    if (reducedLogs.length === 1 && JSON.stringify(reducedLogs).length > maxChars) {
      const singleLog = reducedLogs[0];
      singleLog.message = singleLog.message.substring(0, 200) + '... [truncated due to size limits]';
    }
    
    // Add a note that logs were truncated
    if (reducedLogs.length < logs.length) {
      reducedLogs.push({
        timestamp: new Date().toISOString(),
        level: 'info',
        message: `[Note: ${logs.length - reducedLogs.length} logs were omitted due to size constraints]`,
        source: 'LoggingService'
      });
    }
    
    return reducedLogs;
  }

  /**
   * Save logs to PocketBase
   */
  /**
   * Create a file object from logs for PocketBase file upload
   * @param {Array} logs - Array of log objects
   * @returns {File} - File object containing the full logs
   */
  createLogFile(logs) {
    // Format the logs as text with full details
    const fullLogsText = logs.map(log => {
      return `[${log.timestamp}] [${log.level.toUpperCase()}] [${log.source || 'unknown'}] ${log.message}`;
    }).join('\n');
    
    // Create a file object with the full logs
    const fileName = `agentron-logs-${new Date().toISOString()}.txt`;
    const fileType = 'text/plain';
    
    // Create a Blob and convert to File object
    const blob = new Blob([fullLogsText], { type: fileType });
    return new File([blob], fileName, { type: fileType });
  }

  async saveToPocketBase() {
    if (!this.pocketBaseInstance) {
      // Store logs for later if PocketBase is not available
      this.storePendingLogs();
      return false;
    }

    try {
      // Filter logs to only include errors and warnings in production
      /*const logsToSave = process.env.NODE_ENV === 'production'
       ? this.logs.filter(log => ['error', 'warn'].includes(log.level))
       : this.logs;
      */
       const logsToSave = this.logs;       

      if (logsToSave.length === 0) {
        return true; // No logs to save
      }

      // Format logs to ensure they're serializable
      const formattedLogs = logsToSave.map(log => ({
        timestamp: log.timestamp,
        level: log.level,
        message: log.message,
        source: log.source || 'unknown'
      }));
      
      // Create a log file with the complete, untruncated logs
      const logFile = this.createLogFile(formattedLogs);
      
      // Ensure logs don't exceed character limit for the JSON field
      const limitedLogs = this.ensureLogsUnderCharLimit(formattedLogs);

      // Prepare log data for PocketBase
      const logData = {
        timestamp: new Date().toISOString(),
        // logs field removed to skip saving to the 'logs' column
        has_errors: this.hasErrors ? true : false, // Ensure boolean value
        user_id: this.userInfo.id || null,
        user_name: this.userInfo.name || null,
        app_version: this.appInfo.version || '1.0.0',
        environment: this.appInfo.environment || 'development',
        user_agent: navigator.userAgent || 'unknown',
        url: window.location.href || 'unknown',
        log_file: logFile // Add the log file to the record
      };

      // Save to PocketBase
      await this.pocketBaseInstance.collection(this.pocketBaseCollection).create(logData);
      
      this.originalConsole.info('Logs sent successfully with file attachment');
      return true;
    } catch (error) {
      // Use the original console to avoid infinite loops
      this.originalConsole.error('Failed to save logs to PocketBase:', error);
      // Store logs for later
      this.storePendingLogs();
      return false;
    }
  }

  /**
   * Store logs that couldn't be sent immediately
   */
  storePendingLogs() {
    // Format logs to ensure they're serializable and complete
    const formattedLogs = this.logs.map(log => ({
      timestamp: log.timestamp,
      level: log.level,
      message: log.message,
      source: log.source || 'unknown'
    }));
    
    // Store current logs for later processing
    const pendingLog = {
      timestamp: new Date().toISOString(),
      logs: formattedLogs, // Store the complete, formatted logs
      has_errors: this.hasErrors
    };
    
    this.pendingLogs.push(pendingLog);
    
    // Try to save to localStorage as backup
    try {
      // Get existing pending logs
      let storedLogs = [];
      const existingLogs = localStorage.getItem('agentron_pending_logs');
      if (existingLogs) {
        storedLogs = JSON.parse(existingLogs);
      }
      
      // Add new pending log
      storedLogs.push(pendingLog);
      
      // Limit the number of stored logs to prevent localStorage overflow
      // but ensure we keep the most important ones (with errors)
      if (storedLogs.length > 20) { // Increased from 10 to 20
        // Sort by importance - keep logs with errors first
        storedLogs.sort((a, b) => {
          if (a.has_errors && !b.has_errors) return -1;
          if (!a.has_errors && b.has_errors) return 1;
          return new Date(b.timestamp) - new Date(a.timestamp); // Then by recency
        });
        
        // Keep the 20 most important logs
        storedLogs = storedLogs.slice(0, 20);
      }
      
      localStorage.setItem('agentron_pending_logs', JSON.stringify(storedLogs));
      this.originalConsole.info('Stored pending logs for later processing');
    } catch (e) {
      // Ignore storage errors
      this.originalConsole.warn('Failed to store pending logs in localStorage:', e);
    }
  }

  /**
   * Process any pending logs when PocketBase becomes available
   */
  async processPendingLogs() {
    if (!this.pocketBaseInstance || this.pendingLogs.length === 0) {
      return;
    }

    // Try to load any logs from localStorage
    try {
      const storedLogs = localStorage.getItem('agentron_pending_logs');
      if (storedLogs) {
        const parsedLogs = JSON.parse(storedLogs);
        // Merge with current pending logs, avoiding duplicates
        const timestamps = this.pendingLogs.map(log => log.timestamp);
        parsedLogs.forEach(log => {
          if (!timestamps.includes(log.timestamp)) {
            this.pendingLogs.push(log);
          }
        });
      }
    } catch (e) {
      // Ignore parsing errors
    }

    // Process each pending log entry
    for (const logEntry of this.pendingLogs) {
      try {
        // Define logsToSave from the log entry
        const logsToSave = logEntry.logs;
        
        // Format logs to ensure they're serializable
        const formattedLogs = Array.isArray(logsToSave) ? logsToSave.map(log => ({
          timestamp: log.timestamp || new Date().toISOString(),
          level: log.level || 'info',
          message: log.message || 'No message',
          source: log.source || 'unknown'
        })) : [];
        
        // Create a log file with the complete, untruncated logs
        const logFile = this.createLogFile(formattedLogs);
        
        // Ensure logs don't exceed character limit for the JSON field
        const limitedLogs = this.ensureLogsUnderCharLimit(formattedLogs);
        
        const logData = {
          timestamp: logEntry.timestamp || new Date().toISOString(),
          // logs field removed to skip saving to the 'logs' column
          has_errors: logEntry.has_errors ? true : false, // Ensure boolean value
          user_id: this.userInfo.id || null,
          user_name: this.userInfo.name || null,
          app_version: this.appInfo.version || '1.0.0',
          environment: this.appInfo.environment || 'development',
          user_agent: navigator.userAgent || 'unknown',
          url: 'Saved from pending logs',
          is_pending_log: true,
          log_file: logFile // Add the log file to the record
        };

        await this.pocketBaseInstance.collection(this.pocketBaseCollection).create(logData);
        this.originalConsole.info('Processed pending log with file attachment');
      } catch (error) {
        this.originalConsole.error('Failed to process pending log:', error);
        // Continue processing other logs instead of stopping completely
        continue;
      }
    }

    // Clear pending logs after successful processing
    this.pendingLogs = [];
    try {
      localStorage.removeItem('agentron_pending_logs');
    } catch (e) {
      // Ignore storage errors
    }
  }
}

// Create a singleton instance
const loggingService = new LoggingService();

export default loggingService;