import './_sale-page.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Tag, Loading } from '@carbon/react';
import fetchDataPackages from './fetchDataPackages';
import fetchDataPopusti from './fetchDataPopusti';
import fetchDataTehnologije from './fetchDataTehnologije';
import config from '../../config.json';
import DocumentSignPage from '../DocumentSignPage/DocumentSignPage';
import createNarociloRecord from '../../components/TerenTable/createNarociloRecord';
import PackageManager from './PackageManager';
import CameraComponent from './CameraComponent';
import PersonalDataExtractor from '../../components/VoiceRecognizer/PersonalDataExtractor';

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import {
  Form,
  TextInput,
  Checkbox,
  FormGroup,
  Button,
  Modal,
  Dropdown,
  RadioButtonGroup,
  RadioButton,
  ProgressIndicator,
  ProgressStep,
} from '@carbon/react';
import {
  Add,
  DocumentAdd,
  Close,
  TrashCan,
  ChevronRight,
  Microphone,
  MicrophoneFilled,
} from '@carbon/icons-react'
import GDPRForm from './GDPRForm';
import generateCryptoRandomString from '../../components/generateCryptoRandomString';
import validateTaxNumber from './validateTaxNumber';
import validateEmail from './validateEmail';
import validateMaticnaSt from './validateMaticnaSt';

/**
 * Represents the OrderForm component.
 * @param {Object} narociloData - The data for the order.
 * @param {Function} setBasicData - The function to set the basic data.
 * @param {string} obiskiId - The ID of the obiski record.
 * @param {Array} persons - The array of persons.
 * @param {Function} setPersons - The function to set the persons.
 */
const OrderForm = ({ narociloData, setBasicData, obiskiId, persons = [], setPersons, updatePersonsAfterSave }) => {
  const [currentForm, setCurrentForm] = useState(0); // State to track the current form
  const [narociloId, setNarociloId] = useState(''); // id of the narocilo record
  const [selectedPerson, setSelectedPerson] = useState(null); // Person selected in the dropdown
  const [narociloPerson, setNarociloPerson] = useState(null); // Person selected for the order (to decouple from the dropdown)

  // conditional variables for the form fields
  const [isLegalEntity, setIsLegalEntity] = useState(false);
  const [isMobilePackage, setIsMobilePackage] = useState(false);
  const [isTransferRequest, setIsTransferRequest] = useState(false);

  // Adjust the state management to include "Dodatna oprema"
  const [oprema, setOprema] = useState([]);

  //const [removedPackages, setRemovedPackages] = useState([]); // Array to hold removed packages (includes Oprema)

  //const packageManager = new PackageManager(setAdditionalPackages);

  const [additionalPackageList, setAdditionalPackageList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [allAkcije, setAllAkcije] = useState([]);
  const [tehnologije, setTehnologije] = useState([])
  //const [packages, setPackages] = useState([]); // Mock data for Packages list
  const [allPackages, setAllPackages] = useState([]);
  //const [popusti, setPopusti] = useState([]); // Mock data for Popusti list

  const updateOpremaAkcijaSelection = (opremaId, selectedAkcijaId) => {
    setOprema(prevOprema => prevOprema.map(opremaItem => {
      if (opremaItem.id === opremaId) {
        return {
          ...opremaItem,
          akcija: selectedAkcijaId,
          saveStatus: opremaItem.saveStatus === 'new' ? 'new' : 'updated'
        };
      }
      return opremaItem;
    }));
  };

  const addOprema = async (tipPaketa) => {
    const id = await generateCryptoRandomString();
    setOprema(prevOprema => [
      ...prevOprema,
      {
        id: id,
        paket: '',
        akcija: '',
        saveStatus: 'new',
        tip: tipPaketa,
      },
    ]);
  };

  const updateOpremaSelection = (opremaId, selectedOpremaId) => {
    setOprema(prevOprema => prevOprema.map(oprema => {
      if (oprema.id === opremaId) {
        return {
          ...oprema,
          paket: selectedOpremaId,
          akcija: '',
          saveStatus: oprema.saveStatus === 'new' ? 'new' : 'updated'
        };
      }
      return oprema;
    }));
  };


  const removeOprema = (opremaId) => {
    setOprema(prevOprema => prevOprema.reduce((acc, opremaItem) => {
      if (opremaItem.id === opremaId) {
        if (opremaItem.saveStatus === 'new') {
          return acc; // Do not include this item in the updated list
        } else {
          return [...acc, { ...opremaItem, saveStatus: 'deleted' }];
        }
      }
      return [...acc, opremaItem];
    }, []));
  };





  const handleLegalEntityChange = (value) => {
    // Convert the radio button value back to a boolean and update state
    setIsLegalEntity(value === 'legal');
  };

  // fetch "Akcija" options based on the selected package
  const fetchAkcijaOptions = async (packageId) => {
    const akcijeResult = await fetchDataPopusti(pb, packageId);

    console.log("Akcije result: ", akcijeResult);
    //return akcijeResult.data;

    return akcijeResult.data.map(akcija => ({
      id: akcija.id,
      popust: akcija.popust,
    }));
  };

  // Skeleton function to handle adding a new package record
  const handleAddPackage = () => {
    // Logic to add a new package record will go here
  };

  const [packageType, setPackageType] = useState('');
  const [mobFix, setMobFix] = useState();
  const [zekom, setzekom] = useState();


  const handlePackageTypeChange = (value) => {
    // Convert the radio button value back to a boolean and update state
    setPackageType(value);
    setPaketRel('');
    setPaket('');
    setPackagePrice('');
    setPopust('');
    setAkcijaRel('');
    setEsim(false);
    setMobFix(false);
    setIsMobilePackage(value === 'mobile');
  }


  const navigate = useNavigate();
  const location = useLocation();
  const initialData = location.state?.initialData;
  //console.log("SalePage initial data: ", initialData);

  const { isAuthenticated, currentUserId, currentUserFullName, currentUserPhoneNum, currentUserEmail, pb, login, logout, isTestDatabase } = useAuth();

  const [showToast, setShowToast] = useState(false);

  // Declare state variables for all input fields
  const [street, setStreet] = useState('');
  const [hisnaStevilka, setHisnaStevilka] = useState(''); // hs_stevilka
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [naselje, setNaselje] = useState(''); // naselje
  const [floorApartment, setFloorApartment] = useState(''); // nadstropje_in_st_stanovanja
  const [firstName, setFirstName] = useState(''); // ime
  const [lastName, setLastName] = useState(''); // priimek

  const [taxNumber, setTaxNumber] = useState(''); // davcna_st
  const [isTaxNumberValid, setIsTaxNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  // Function to update tax number and check its validity
  const handleTaxNumberChange = (value) => {
    setTaxNumber(value);
    const isValid = validateTaxNumber(value);

    setIsTaxNumberValid(isValid);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    // Check if the email is valid
    if (!value) {
      setIsEmailValid(true);
    } else {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
    }
  };

  const [maticnaStPodjetje, setMaticnaStPodjetje] = useState(''); // maticna_st_podjetje
  const [isMaticnaStValid, setIsMaticnaStValid] = useState(true);

  const handleMaticnaStPodjetjeChange = (value) => {
    setMaticnaStPodjetje(value);

    const isValid = validateMaticnaSt(value);
    setIsMaticnaStValid(isValid);
  };



  const [contactPhone, setContactPhone] = useState(''); // kontakt_tek
  const [email, setEmail] = useState(''); // email
  const [landlinePhone, setLandlinePhone] = useState(''); // st_za_prenos
  const [paket, setPaket] = useState(''); // paket
  const [packagePrice, setPackagePrice] = useState(''); // paket
  const [paketRel, setPaketRel] = useState(''); // paket
  const [akcijaRel, setAkcijaRel] = useState(''); // akcija
  const [popust, setPopust] = useState(''); // popust
  const [popustVezava, setPopustVezava] = useState(''); // popust_vezava
  const [notes, setNotes] = useState(''); // opombe
  const [numberTransfer, setNumberTransfer] = useState(false); // prenos_telefonske
  const [accountNumber, setAccountNumber] = useState(''); // st_racuna_donorja_ali_puk
  const [operator, setOperator] = useState(''); // operater_donor
  const [subscriber, setSubscriber] = useState(''); // narocnik
  const [subscriberNumberUsername, setSubscriberNumberUsername] = useState(''); // st_narocnika_ali_uporabnisko_ime
  const [servicesToTerminate, setServicesToTerminate] = useState(''); // storitve_za_prekinitev
  const [isConnectionFee, setIsConnectionFee] = useState(false); // is_prikljucnina
  const [connectionFee, setConnectionFee] = useState('0'); // prikljucnina
  const [benefitsBinding, setBenefitsBinding] = useState(''); // ugodnost_ob_vezavi
  const [additionalServicesPackage, setAdditionalServicesPackage] = useState(''); // paketStoritev
  const [equipment, setEquipment] = useState(''); // oprema
  const [additionalInternetServices, setAdditionalInternetServices] = useState(''); // dodatne_storitve_interneta
  const [additionalPhoneServices, setAdditionalPhoneServices] = useState(''); // dodatne_storitve_telefonije
  const [consentToTransfer, setConsentToTransfer] = useState(false); // prenos_stevilke_soglasje
  const [consentToTerminate, setConsentToTerminate] = useState(false); // soglasje_za_prekinitev
  const [standingOrderPayment, setStandingOrderPayment] = useState(false); // placilo_s_trajnikom
  const [racunSMS, setRacunSMS] = useState(true); // racun_sms
  const [racunEmail, setRacunEmail] = useState(true); // racun_email
  const [racunEmailNaslov, setRacunEmailNaslov] = useState(''); // racun_email_naslov
  const [racunEmailNaslovValid, setRacunEmailNaslovValid] = useState(true);
  const handleRacunEmailNaslovChange = (value) => {
    setRacunEmailNaslov(value);
    if (!value) {
      setRacunEmailNaslovValid(true);
    } else {
      const isValid = validateEmail(value);
      setRacunEmailNaslovValid(isValid);
    }
  };

  const [racunBanka, setRacunBanka] = useState(false); // racun_banka
  const [racunBankaIBAN, setRacunBankaIBAN] = useState(''); // racun_banka_iban
  const [racunPortal, setRacunPortal] = useState(false); // racun_portal
  const [racunPisno, setRacunPisno] = useState(false); // racun_pisno
  const [racunPisnoUlica, setRacunPisnoUlica] = useState(''); // racun_pisno_ulica
  const [racunPisnoPostnaSt, setRacunPisnoPostnaSt] = useState(''); // racun_pisno_postna_st
  const [racunPisnoMesto, setRacunPisnoMesto] = useState(''); // racun_pisno_mesto
  const [podjetje, setPodjetje] = useState(''); // podjetje
  const [zavezanecZaDDV, setZavezanecZaDDV] = useState(false); // zavezanec_za_ddv
  const [predcasnaAktivacija, setPredcasnaAktivacija] = useState(false); // predcasna_aktivacija
  const [osebniDokument, setOsebniDokument] = useState(''); // osebni_dokument
  const [mobilniPaket, setMobilniPaket] = useState(''); // mobilni_paket
  const [prenosTelefonskaSt, setPrenosTelefonskaSt] = useState(''); // prenos_telefonska_st
  const [prenosIme, setPrenosIme] = useState(''); // prenos_ime
  const [prenosNaslov, setPrenosNaslov] = useState(''); // prenos_naslov
  const [prenosPostnaKraj, setPrenosPostnaKraj] = useState(''); // prenos_postna_kraj
  const [prenosDavcna, setPrenosDavcna] = useState(''); // prenos_davcna
  const [prenostelMsisdn1, setPrenostelMsisdn1] = useState(''); // prenostel_msisdn1
  const [prenostelPuk1a, setPrenostelPuk1a] = useState(''); // prenostel_puk1a
  const [prenostelMsisdn2, setPrenostelMsisdn2] = useState(''); // prenostel_msisdn2
  const [prenostelPuk2a, setPrenostelPuk2a] = useState(''); // prenostel_puk2a
  const [prenostelStRacuna, setPrenostelStRacuna] = useState(''); // prenostel_st_racuna
  const [prenostelDajalec, setPrenostelDajalec] = useState(''); // prenostel_dajalec
  const [prenostelImePodjetje, setPrenostelImePodjetje] = useState(''); // prenostel_ime_podjetje
  const [prenostelNaslov, setPrenostelNaslov] = useState(''); // prenostel_naslov
  const [prenostelPostnaSt, setPrenostelPostnaSt] = useState(''); // prenostel_postna_st
  const [prenostelKraj, setPrenostelKraj] = useState(''); // prenostel_kraj
  const [prenostelDavcna, setPrenostelDavcna] = useState(''); // prenostel_davcna
  const [prenostelDavcnaValid, setPrenostelDavcnaValid] = useState(true); // prenostel_davcna_valid

  const handlePrenosDavcnaChange = (value) => {
    setPrenostelDavcna(value);
    const isValid = validateTaxNumber(value);
    setPrenostelDavcnaValid(isValid);
  };
  const [prenostelPooblascen, setPrenostelPooblascen] = useState(''); // prenostel_pooblascen
  const [prenostelPrekinitevDajalec, setPrenostelPrekinitevDajalec] = useState(false); // prenostel_prekinitev_dajalec
  const [prenostelOhranitevDajalec, setPrenostelOhranitevDajalec] = useState(false); // prenostel_ohranitev_dajalec
  const [prenostelImeNarocnika, setPrenostelImeNarocnika] = useState(''); // prenostel_ime_narocnika
  const [prenostelNarocniskaStUporIme, setPrenostelNarocniskaStUporIme] = useState(''); // prenostel_narocniska_st_upor_ime
  const [prenostelPrekiniOhraniSirokopasovni, setPrenostelPrekiniOhraniSirokopasovni] = useState(false); // prenostel_prekini_ohrani_sirokopasovni
  const [prenostelOhraniTv, setPrenostelOhraniTv] = useState(false); // prenostel_ohrani_tv
  const [prenostelOhraniInternet, setPrenostelOhraniInternet] = useState(false); // prenostel_ohrani_internet
  const [prenostelOhraniDomeno, setPrenostelOhraniDomeno] = useState(false); // prenostel_ohrani_domeno
  const [prenostelDomena, setPrenostelDomena] = useState(''); // prenostel_domena
  const [prenostelOhraniEmail, setPrenostelOhraniEmail] = useState(false); // prenostel_ohrani_email
  const [prenostelEmail, setPrenostelEmail] = useState(''); // prenostel_email



  const [prenostelEmailValid, setPrenostelEmailValid] = useState(true); // prenostel_email_valid
  const handlePrenostelEmailChange = (value) => {
    setPrenostelEmail(value);
    if (!value) {
      setPrenostelEmailValid(true);
    } else {
      const isValid = validateEmail(value);
      setPrenostelEmailValid(isValid);
    }
  };
  const [tehnologija, setTehnologija] = useState(''); // tehnologija
  const [esim, setEsim] = useState(); // esim

  // gdpr fields for checkboxes
  // Initialize checkbox states based on database field naming
  const [gdprConsent, setGdprConsent] = useState({
    cb_1_1: true, cb_1_2: true, cb_1_3: true, cb_1_4: true, cb_1_5: true, cb_1_6: true, cb_1_7: true,
    cb_2_1: true, cb_2_2: true, cb_2_3: true, cb_2_4: true, cb_2_5: true, cb_2_6: true, cb_2_7: true,
    cb_3_1: true, cb_3_2: true, cb_3_3: true, cb_3_4: true, cb_3_5: true, cb_3_6: true, cb_3_7: true
  });
  const [gdprChanged, setGdprChanged] = useState(false);
  const [gdprSigned, setGdprSigned] = useState(false);

  const handleGdprSigned = (value) => {
    console.log("GDPR signed: ", value);
    setGdprConsent(prevState => ({
      ...prevState,
      signed: value,
    }));
    setGdprChanged(true);
    setGdprSigned(value);
    //handleSave();
  };

  useEffect(() => {
    //console.log('GDPR consent:', gdprConsent);
    if (gdprSigned) {
      handleSave(); // Pass true indicating gdprChanged is updated
    }
  }, [gdprSigned]);

  const [isNarociloFinished, setIsNarociloFinished] = useState(false); // state if the current form is finished

  // when saved, save the id of obiski record, and then use that id to save the attachments or update the record
  //const [obiskiId, setObiskiId] = useState(''); // id of the obiski record

  const [documentType, setDocumentType] = useState('narocilo'); // Type of document to sign
  const [documentData, setDocumentData] = useState([]); // Data to be signed

  const [signedDocuments, setSignedDocuments] = useState([]); // Array to hold signed documents
  const [sendToClient, setSendToClient] = useState(false); // Send the signed document to the client

  const [drugNarocnik, setDrugNarocnik] = useState(false); // Ali je naročnik druga oseba?

  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

  const handleTehnologijaChange = (value) => {
    setTehnologija(value);
    setPaketRel('');
    setPaket('');
    setPackagePrice('');
    setAkcijaRel('');
    setPopust('');
    // Filter the packages based on the selected technology
    //allPackages.filter(pkg => pkg.tehnologija === tehnologija.id)
    //const filteredPackages = allPackages.filter(pkg => pkg.tehnologija === value.id);
    //setPackages(filteredPackages);

  }



  const handleSignature = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('sl-SI', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const krajDatum = `${city}, ${formattedDate}`;
    // Prepare the data to be sent to the service

    // popusti: combine strings akcijaRel.popust and all record in the oprema array, field akcija
    const popusti = akcijaRel?.popust;
    console.log("Oprema: ", oprema);
    console.log("All oprema");
    const additionalServicesPackage = oprema
      .filter(opremaItem => opremaItem.tip === 'additional')
      .map(opremaItem => {
        const pkg = additionalPackageList.find(item => item.id === opremaItem.paket);
        return pkg ? pkg.description : '';
      })
      .join(', ');
    const opremaPopusti = oprema
      .filter(opremaItem => opremaItem.tip !== 'additional')
      .map(opremaItem => {
        const akcija = allAkcije.find(item => item.id === opremaItem.akcija);
        return akcija ? akcija.popust : '';
      })
      .join(', ');
    const opremaAdditionalPopusti = oprema
      .filter(opremaItem => opremaItem.tip === 'additional')
      .map(opremaItem => {
        const akcija = allAkcije.find(item => item.id === opremaItem.akcija);
        return akcija ? akcija.popust : '';
      })
      .join(', ');
    //const opremaPopusti = oprema.map(opremaItem => opremaItem.akcija).join(', ');
    //const opremaAdditionalPopusti = oprema.filter(opremaItem => opremaItem.tip === 'additional').map(opremaItem => opremaItem.akcija).join(', ');
    const popustiString = `${popusti}, ${opremaPopusti}`;
    const popustiAdditionalString = `${popusti}, ${opremaAdditionalPopusti}`;

    const toUpperCaseData = (data) => {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) =>
          [
            key,
            (key === 'form_selection' || value === "/Yes" || value === "/No")
              ? value
              : (typeof value === 'string' ? value.toUpperCase() : value)
          ]
        )
      );
    };


    const requestDataNarocilo = toUpperCaseData({
      "form_selection": "narocilo", // izbran pdf
      "Racun_ulica_hisna_st": racunPisnoUlica,
      "Racun_postna_st": racunPisnoPostnaSt,
      "Racun_mesto": racunPisnoMesto,
      "Narocnik_naslov_hisna_st": `${street || ''} ${hisnaStevilka || ''}`,
      "Narocnik_ime_priimek": podjetje ? podjetje : `${firstName} ${lastName}`,
      "Narocnik_st_stanovanja_nadstropje": floorApartment,
      "Narocnik_postna_st_kraj": `${postalCode} ${city}`,
      "Narocnik_GSM": contactPhone,
      "Narocnik_davcna": taxNumber,
      "Narocnik_email": email,
      //"Storitve_prikljucnina_znesek": connectionFee,
      "Storitve_ugodnosti_ob_vezavi": popust,//benefitsBinding,
      "Storitve_paket_storitev": paketRel.paket,//paket,
      "Tip": paketRel.tip,
      "Storitve_cena": packagePrice,
      // Storitve_dodatne_storitve_paket: filter oprema (tip=additional) and join together as string all the paket fields
      "Storitve_dodatne_storitve_paket": opremaAdditionalPopusti,//oprema.filter(opremaItem => opremaItem.tip === 'additional').map(opremaItem => opremaItem.paket).join(', '),  //additionalServicesPackage,
      "Storitve_oprema": opremaPopusti,
      "Stortive_dodatne_storitve_interneta": additionalInternetServices,
      "Storitve_dodatne_storitve_telefonije": additionalPhoneServices,
      "Storitve_opombe": notes + (podjetje ? ` (Zastopnik: ${firstName} ${lastName})` : '') + (zekom && numberTransfer ? ' Prekinitev po ZEKom' : ''),
      "Email_racun": racunEmail ? "/Yes" : "/No",
      "Racun_email_naslov": racunEmailNaslov,
      "SMS_racun": racunSMS ? "/Yes" : "/No",
      "Spletna_banka_racun": racunBanka ? "/Yes" : "/No",
      "Portal_racun": racunPortal ? "/Yes" : "/No",
      "Racun_IBAN": racunBankaIBAN,
      "Stortive_svetovalec": currentUserFullName,
      "Kraj_in_datum": krajDatum,
      // Any additional fields that need to be mapped
    })
    const requestDataPogodba = toUpperCaseData({
      "form_selection": "pogodba",
      "Narocnik_ime_priimek": `${firstName} ${lastName}`,
      "Narocnik_zakoniti_zastopnik_druzbe": podjetje,
      "Narocnik_ulica_hisna_st": `${street || ''} ${hisnaStevilka || ''}`,
      "Narocnik_postna_st": postalCode,
      "Narocnik_mesto": city,
      "Narocnik_telefonska": landlinePhone,
      "Narocnik_mobilna": contactPhone,
      "Narocnik_email": email,
      "Narocnik_davcna": taxNumber,
      "Narocnik_zavezanec_ddv": zavezanecZaDDV ? "Da" : "Ne",
      "Narocnik_maticna_st": maticnaStPodjetje,
      "Racun_sms": racunSMS ? "/Yes" : "/No",
      "Racun_na_email": racunEmail ? "/Yes" : "/No",
      "Racun_email": racunEmailNaslov,
      "Racun_na_banko": racunBanka ? "/Yes" : "/No",
      "Racun_IBAN": racunBankaIBAN,
      "Racun_na_portal": racunPortal ? "/Yes" : "/No",
      "Racun_ulica_hisna_st": racunPisnoUlica,
      "Racun_postna_st": racunPisnoPostnaSt,
      "Racun_mesto": racunPisnoMesto,
      "Storitev_naslov_prikljucka": `${street || ''} ${hisnaStevilka || ''}, ${postalCode} ${city}`,
      "Storitev_st_stanovanja_nadstropje": floorApartment,
      "Storitev_naziv_paketa": paket,
      "Storitev_dodatne_storitve": additionalServicesPackage,//oprema.filter(opremaItem => opremaItem.tip === 'additional').map(opremaItem => opremaItem.paket).join(', '),//additionalServicesPackage,
      "Storitev_operater": prenostelDajalec,//operator,
      "Storitev_tel_fiksna": prenostelMsisdn1,//landlinePhone,
      "Storitev_prenos_st": numberTransfer ? "Da" : "Ne",
      "Predcasna_aktivacija": predcasnaAktivacija ? "/Yes" : "/No",
      "Ugodnosti_naziv_akcije": popustiAdditionalString,//popust,
      "Ugodnosti_vezava_meseci": "24", //popustVezava,
      "Predcasna_aktivacija" : "/Yes" 


      // TODO: predčasna aktivacija; priloga (objava v imeniku)
    })
    const requestDataPrijavnicaMobilna = toUpperCaseData({
      "form_selection": "prijavnica-mobilna",
      "Narocnik_ime_priimek_podjetje": `${firstName} ${lastName} ${podjetje}`,
      "Narocnik_ulica_hisna_st": `${street || ''} ${hisnaStevilka || ''}`,
      "Narocnik_postna_st_kraj": `${postalCode} ${city}`,
      "Narocnik_davcna_st": taxNumber,
      "Narocnik_zavezanec": zavezanecZaDDV ? "Da" : "Ne",
      "Narocnik_telefon": contactPhone,
      "Narocnik_vrsta_dokumenta": osebniDokument,
      "Narocnik_zastopnik_podjetja": podjetje,
      "Narocnik_maticna": maticnaStPodjetje,
      "Narocnik_email": email,
      "Paket": `${paketRel?.paket}${akcijaRel?.popust ? `, ${akcijaRel?.popust}` : ''}${mobFix ? ' + MOB/FIX' : ''}`, "Prenos_mobilna_st": prenostelMsisdn1,
      "Prenos_donor": prenostelDajalec,
      "Prenos_zadnji_racun": prenostelStRacuna,
      "Prenos_ime_priimek_podjetje": prenostelImePodjetje !== "" ? prenostelImePodjetje : prenosIme,
      "Prenos_naslov_sedez": prenostelNaslov !== "" ? prenostelNaslov : prenosNaslov,
      "Prenos_postna_st_kraj": prenostelPostnaSt !== "" ? prenostelPostnaSt + " " + prenostelKraj : `${prenosPostnaKraj}`,
      "Prenos_davcna_st": numberTransfer ? (prenostelDavcna !== "" ? prenostelDavcna : taxNumber) : "", "Oprema_proizvajalec": "",
      "Oprema_model": "",
      "Obdobje_vezave": "",
      "Stevilo_obrokov": "",
      "Aneks_ime_priimek_podjetje": "",
      "Aneks_ulica_hisna_st": "",
      "Aneks_postna_st_kraj": "",
      "Aneks_st_kupca": "",
      "Racun_email": racunEmailNaslov,
      "Racun_IBAN": racunBankaIBAN,
      "Racun_ulica_hisna_st": racunPisnoUlica,
      "Racun_postna_st": racunPisnoPostnaSt,
      "Racun_mesto": racunPisnoMesto,
      "Datum_podpis_narocitelj": formattedDate,
      //"Datum_podpis_narocnik_fiksnih": formattedDate,
      "Podpis_posrednik": currentUserFullName ? currentUserFullName : "-",
      "Racun_sms": racunSMS ? "/Yes" : "/No",
      "Racun_na_email": racunEmail ? "/Yes" : "/No",
      "Racun_banka": racunBanka ? "/Yes" : "/No",
      "Racun_portal": racunPortal ? "/Yes" : "/No",
    })
    const requestDataZahtevaZaPrenosMNP = toUpperCaseData({
      "form_selection": "zahteva-za-prenos",
      "nacionalna_st": prenostelMsisdn1,
      "puk": prenostelStRacuna,//prenostelPuk1a,
      //"nacionalna_st2": prenostelMsisdn2,
      //"puk2": prenostelPuk2a,
      "st_racuna_dajalec": prenostelStRacuna,
      "naziv-operaterja_dajalec": prenostelDajalec,
      "Narocnik_ime_priimek": prenostelImePodjetje !== "" ? prenostelImePodjetje : `${firstName} ${lastName}`,//prenostelImeNarocnika,
      "Narocnik_ulica_hisna_st": prenostelNaslov !== "" ? prenostelNaslov : `${street || ''} ${hisnaStevilka || ''}`,
      "Narocnik_postna_st": prenostelPostnaSt !== "" ? prenostelPostnaSt : `${postalCode}`,
      "Narocnik_kraj": prenostelKraj !== "" ? prenostelKraj : `${city}`,
      "Narocnik_davcna": prenostelDavcna !== "" ? prenostelDavcna : taxNumber,
      "Narocnik_ime_priimek_pooblascene": prenostelPooblascen,
      "Prekinitev_pri_dajalcu": prenostelPrekinitevDajalec ? "/Yes" : "/No",
      "Ohranitev_pri_dajalcu": prenostelOhranitevDajalec ? "/Yes" : "/No",
      "Prekinitev_ohranitev": "/Yes",
      "Kraj_datum": krajDatum,
      "Ime_priimek_narocnik_sirokopasovnih": `${firstName} ${lastName}`,
      "Narocniska_st_uporrabnisko_ime": prenostelNarocniskaStUporIme,
      "Prekinitev_ohranitev": prenostelPrekiniOhraniSirokopasovni ? "/Yes" : "/No",
      "TV": prenostelOhraniTv ? "/Yes" : "/No",
      "Internet": prenostelOhraniInternet ? "/Yes" : "/No",
      "Domena_ohrani": prenostelOhraniDomeno ? "/Yes" : "/No",
      "Naslov_domene": prenostelDomena,
      "Email_ohrani": prenostelOhraniEmail ? "/Yes" : "/No",
      "Email_naslov": prenostelEmail,
      "datum_in_ura_podpisa": "",
      "Odstop_zekom": zekom ? "/Yes" : "/No",
      "Odstop_zekom2": zekom ? "/Yes" : "/No",
      "Prekinitev_ohranitev": !(prenostelOhraniEmail || prenostelOhraniInternet || prenostelOhraniTv) ? "/Yes" : "/No",
      "Ohranitev": (prenostelOhraniEmail || prenostelOhraniInternet || prenostelOhraniTv) ? "/Yes" : "/No",
    })
    const requestDataZahtevaZaPrenosFNP = toUpperCaseData({
      "form_selection": "zahteva-za-prenos",
      "nacionalna_st": prenostelMsisdn1,
      "puk": prenostelPuk1a,
      "nacionalna_st2": prenostelMsisdn2,
      "puk2": prenostelPuk2a,
      "st_racuna_dajalec": prenostelStRacuna,
      "naziv-operaterja_dajalec": prenostelDajalec,
      "Narocnik_ime_priimek": `${firstName} ${lastName}`,//prenostelImeNarocnika,
      "Narocnik_ulica_hisna_st": prenostelNaslov !== "" ? prenostelNaslov : `${street || ''} ${hisnaStevilka || ''}`,
      "Narocnik_postna_st": prenostelPostnaSt !== "" ? prenostelPostnaSt : `${postalCode}`,
      "Narocnik_kraj": prenostelKraj !== "" ? prenostelKraj : `${city}`,
      "Narocnik_davcna": prenostelDavcna !== "" ? prenostelDavcna : taxNumber,
      "Narocnik_ime_priimek_pooblascene": prenostelPooblascen,
      "Prekinitev_pri_dajalcu": prenostelPrekinitevDajalec ? "/Yes" : "/No",
      "Ohranitev_pri_dajalcu": prenostelOhranitevDajalec ? "/Yes" : "/No",
      "Kraj_datum": krajDatum,
      "Ime_priimek_narocnik_sirokopasovnih": `${firstName} ${lastName}`,
      "Narocniska_st_uporrabnisko_ime": prenostelNarocniskaStUporIme,
      "Prekinitev_ohranitev": prenostelPrekiniOhraniSirokopasovni ? "/Yes" : "/No",
      "TV": prenostelOhraniTv ? "/Yes" : "/No",
      "Internet": prenostelOhraniInternet ? "/Yes" : "/No",
      "Domena_ohrani": prenostelOhraniDomeno ? "/Yes" : "/No",
      "Naslov_domene": prenostelDomena,
      "Email_ohrani": prenostelOhraniEmail ? "/Yes" : "/No",
      "Email_naslov": prenostelEmail,
      "datum_in_ura_podpisa": "",
      "Odstop_zekom": zekom ? "/Yes" : "/No",
      "Odstop_zekom2": zekom ? "/Yes" : "/No",
      "Prekinitev_ohranitev": !(prenostelOhraniEmail || prenostelOhraniInternet || prenostelOhraniTv) ? "/Yes" : "/No",
      "Ohranitev": (prenostelOhraniEmail || prenostelOhraniInternet || prenostelOhraniTv) ? "/Yes" : "/No",
    })
    const requestDataGdpr = toUpperCaseData({
      "form_selection": "gdpr",
      "ime_priimek": `${firstName} ${lastName}`,
      "naslov": `${street || ''} ${hisnaStevilka || ''}`,
      "postna_st": postalCode,
      "mesto": city,
      "email": email,
      "mob_st": contactPhone,
      "tel_st": landlinePhone,
      "kraj_datum": krajDatum,
      "cb_1_1": gdprConsent.cb_1_1 ? "/Yes" : "/No",
      "cb_1_2": gdprConsent.cb_1_2 ? "/Yes" : "/No",
      "cb_1_3": gdprConsent.cb_1_3 ? "/Yes" : "/No",
      "cb_1_4": gdprConsent.cb_1_4 ? "/Yes" : "/No",
      "cb_1_5": gdprConsent.cb_1_5 ? "/Yes" : "/No",
      "cb_1_6": gdprConsent.cb_1_6 ? "/Yes" : "/No",
      "cb_1_7": gdprConsent.cb_1_7 ? "/Yes" : "/No",
      "cb_2_1": gdprConsent.cb_2_1 ? "/Yes" : "/No",
      "cb_2_2": gdprConsent.cb_2_2 ? "/Yes" : "/No",
      "cb_2_3": gdprConsent.cb_2_3 ? "/Yes" : "/No",
      "cb_2_4": gdprConsent.cb_2_4 ? "/Yes" : "/No",
      "cb_2_5": gdprConsent.cb_2_5 ? "/Yes" : "/No",
      "cb_2_6": gdprConsent.cb_2_6 ? "/Yes" : "/No",
      "cb_2_7": gdprConsent.cb_2_7 ? "/Yes" : "/No",
      "cb_3_1": gdprConsent.cb_3_1 ? "/Yes" : "/No",
      "cb_3_2": gdprConsent.cb_3_2 ? "/Yes" : "/No",
      "cb_3_3": gdprConsent.cb_3_3 ? "/Yes" : "/No",
      "cb_3_4": gdprConsent.cb_3_4 ? "/Yes" : "/No",
      "cb_3_5": gdprConsent.cb_3_5 ? "/Yes" : "/No",
      "cb_3_6": gdprConsent.cb_3_6 ? "/Yes" : "/No",
      "cb_3_7": gdprConsent.cb_3_7 ? "/Yes" : "/No",
    })

    const documentsData = [];

    // Add additional documents based on the form data
    if (isMobilePackage) {
      documentsData.push({ url: config.formFillServiceUrl, data: requestDataPrijavnicaMobilna, label: "Prijavnica Mobilna" });
    } else { // fix naročilo
      documentsData.push({ url: config.formFillServiceUrl, data: requestDataNarocilo, label: "Naročilo" });
    }

    if (tehnologija.tuja && !isMobilePackage) {
      documentsData.push({ url: config.formFillServiceUrl, data: requestDataPogodba, label: "Pogodba" });
    }

    if (numberTransfer) {
      if (isMobilePackage) {
        documentsData.push({ url: config.formFillServiceUrl, data: requestDataZahtevaZaPrenosMNP, label: "Zahteva za Prenos - MNP" });
      } else {
        documentsData.push({ url: config.formFillServiceUrl, data: requestDataZahtevaZaPrenosFNP, label: "Zahteva za Prenos - FNP" });
      }
      //documentsData.push({ url: config.formFillServiceUrl, data: requestDataZahtevaZaPrenos, label: "Zahteva za Prenos" });
    }

    if (!(selectedPerson?.expand?.gdpr?.signed)) {
      documentsData.push({ url: config.formFillServiceUrl, data: requestDataGdpr, label: "GDPR" });
    }

    // Set state to pass to DocumentSignPage
    setDocumentData(documentsData);

    /*
    let reqData = requestDataNarocilo;
    if (currentForm === 1) {
      setDocumentType('narocilo');
      reqData = requestDataNarocilo;
    } else if (currentForm === 2) {
      setDocumentType('pogodba');
      reqData = requestDataPogodba;
    } else if (currentForm === 3) {
      setDocumentType('prijavnica_mobilna');
      reqData = requestDataPrijavnicaMobilna;
    } else if (currentForm === 4) {
      setDocumentType('zahteva_za_prenos');
      reqData = requestDataZahtevaZaPrenos;
    }
    try {
      const response = await fetch(config.formFillServiceUrl, {
        method: 'POST',
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      // Handle the response from the service - service is sending PDF data in this way:
      //     return send_file(flattened_pdf_stream, download_name="flattened_form.pdf", as_attachment=True)
      const responseData = await response.blob();
      const blobUrl = URL.createObjectURL(responseData);
      setPdfBlobUrl(blobUrl);


      //const navigate = useNavigate();
      //navigate('/podpis', { state: { pdfData: blobUrl } });


      //console.log('Signature service response:', responseData);

      // Additional actions based on the response
    } catch (error) {
      console.error('Error calling signature service:', error);
    }*/
  };

  useEffect(() => {
    const fetchTehnologije = async () => {
      try {
        const response = await fetchDataTehnologije(pb);
        setTehnologije(response.data);
      } catch (error) {
        console.error("Error fetching Tehnologije:", error);
      }
    };

    const fetchAllAkcije = async () => {
      try {
        const response = await fetchDataPopusti(pb); // Assuming this fetches all entries
        setAllAkcije(response.data);
      } catch (error) {
        console.error("Error fetching Akcije:", error);
      }
    };

    const fetchAllPackages = async () => {
      const packageResult = await fetchDataPackages(pb);
      setAllPackages(packageResult.data);
    };

    const fetchAdditionalPackageList = async () => {
      const packageResult = await fetchDataPackages(pb, "additional");
      setAdditionalPackageList(packageResult.data);
    };
    const fetchEquipmentList = async () => {
      const equipmentResult = await fetchDataPackages(pb, "opremafix");
      setEquipmentList(equipmentResult.data);
    };
    /*
    const fetchPopusti = async () => {
      const popustiResult = await fetchDataPopusti(pb);
      setPopusti(popustiResult.data);
    };*/

    const initializeOprema = async () => {
      const opremaData = [
        {
          id: await generateCryptoRandomString(),
          paket: '', // This will hold the selected package ID
          akcija: '', // This will hold the selected "Akcija" ID
          saveStatus: 'new', // This will hold the save status of the package
          tip: 'opremafix',
        },
        {
          id: await generateCryptoRandomString(),
          paket: '', // This will hold the selected package ID
          akcija: '', // This will hold the selected "Akcija" ID
          saveStatus: 'new', // This will hold the save status of the package
          tip: 'additional',
        }
      ];
      setOprema(opremaData);
      console.log('Oprema:', opremaData);
    };

    //initializeOprema();



    fetchTehnologije();
    fetchAllAkcije();
    fetchAllPackages();
    fetchAdditionalPackageList();
    fetchEquipmentList();
    //fetchPopusti();
  }, []);
  /*
    useEffect(() => {
  
      // if mobile selected, filter the packages; if fixed selected, set to empty array (will be filtered on technology change)
      if (packageType === 'mobile') {
        const filteredPackages = allPackages.filter(pkg => pkg.tip === 'mobile');
        setPackages(filteredPackages);
      } else {
        if (tehnologija) {
          const filteredPackages = allPackages.filter(pkg => pkg.tehnologija === tehnologija.id);
          setPackages(filteredPackages);
        } else {
          setPackages([]);
        }
      }
  
    }, [packageType]); // An empty dependency array ensures this useEffect runs only once
  
  */
  const handleCancel = () => {
    // Navigate back to the /plan page
    handleSave();
    navigate('/plan');
  };


  const setNarociloData = async (narociloData) => {
    try {

      //console.log('!!!narociloData:', narociloData);

      // Update form fields with data from the "obiski" record
      // Replace 'field' with the actual field names in the "obiski" record
      setFirstName(narociloData.ime || '');
      setLastName(narociloData.priimek || '');
      setTaxNumber(narociloData.davcna_st || '');
      setContactPhone(narociloData.kontakt_tel || '');
      setEmail(narociloData.email || '');
      setLandlinePhone(narociloData.st_za_prenos || '');
      setFloorApartment(narociloData.nadstropje_in_st_stanovanja || '');
      setStreet(narociloData.ulica || '');
      setHisnaStevilka(narociloData.hisna_st || '');
      setPostalCode(narociloData.postna_st || '');
      setCity(narociloData.posta || '');
      setPaket(narociloData.paketStoritev || '');
      setPackagePrice(narociloData.cena_paketa_storitev || '');
      //setPaketRel(`${narociloData.paketStoritev} (Cena: ${narociloData.cena_paketa_storitev} EUR)`);
      setEsim(narociloData.esim || false);
      setMobFix(narociloData.mob_fix || '');
      setzekom(narociloData.zekom || '');
      setNotes(narociloData.opombe || '');
      setNumberTransfer(narociloData.prenos_telefonske || false);
      setAccountNumber(narociloData.st_racuna_donorja_ali_puk || '');
      setOperator(narociloData.operater_donor || '');
      setSubscriber(narociloData.narocnik || '');
      setSubscriberNumberUsername(narociloData.st_narocnika_ali_uporabnisko_ime || '');
      setServicesToTerminate(narociloData.storitve_za_prekinitev || '');
      setIsConnectionFee(narociloData.is_prikljucnina || false);
      setConnectionFee(narociloData.prikljucnina || '');
      setBenefitsBinding(narociloData.ugodnost_ob_vezavi || '');
      setAdditionalServicesPackage(narociloData.paketStoritev || '');
      setEquipment(narociloData.oprema || '');
      setAdditionalInternetServices(narociloData.dodatne_storitve_interneta || '');
      setAdditionalPhoneServices(narociloData.dodatne_storitve_telefonije || '');
      setConsentToTransfer(narociloData.prenos_stevilke_soglasje || false);
      setConsentToTerminate(narociloData.soglasje_za_prekinitev || false);
      setStandingOrderPayment(narociloData.placilo_s_trajnikom || false);
      setRacunSMS(narociloData.racun_sms || false);
      setRacunEmail(narociloData.racun_email || false);
      setRacunEmailNaslov(narociloData.racunEmailNaslov || '');
      setRacunBanka(narociloData.racun_banka || false);
      setRacunBankaIBAN(narociloData.racunBankaIBAN || '');
      setRacunPortal(narociloData.racunPortal || false);
      setRacunPisno(narociloData.racun_pisno || false);
      setRacunPisnoUlica(narociloData.racunPisnoUlica || '');
      setRacunPisnoPostnaSt(narociloData.racunPisnoPostnaSt || '');
      setRacunPisnoMesto(narociloData.racunPisnoMesto || '');
      setPodjetje(narociloData.podjetje || '');
      setZavezanecZaDDV(narociloData.zavezanec_za_ddv || false);
      setMaticnaStPodjetje(narociloData.maticna_st_podjetje || '');
      setPredcasnaAktivacija(narociloData.predcasna_aktivacija || false);
      setOsebniDokument(narociloData.osebni_dokument || '');
      setPopust(narociloData.popust || '');
      setPopustVezava(narociloData.popust_vezava || '');
      setMobilniPaket(narociloData.mobilni_paket || '');
      setPrenosTelefonskaSt(narociloData.prenos_telefonska_st || '');
      setPrenosIme(narociloData.prenos_ime || '');
      setPrenosNaslov(narociloData.prenos_naslov || '');
      setPrenosPostnaKraj(narociloData.prenos_postna_kraj || '');
      setPrenosDavcna(narociloData.prenos_davcna || '');
      setPrenostelMsisdn1(narociloData.prenostel_msisdn1 || '');
      setPrenostelPuk1a(narociloData.prenostel_puk1a || '');
      setPrenostelMsisdn2(narociloData.prenostel_msisdn2 || '');
      setPrenostelPuk2a(narociloData.prenostel_puk2a || '');
      setPrenostelStRacuna(narociloData.prenostel_st_racuna || '');
      setPrenostelDajalec(narociloData.prenostel_dajalec || '');
      setPrenostelImePodjetje(narociloData.prenostel_ime_podjetje || '');
      setPrenostelNaslov(narociloData.prenostel_naslov || '');
      setPrenostelPostnaSt(narociloData.prenostel_postna_st || '');
      setPrenostelKraj(narociloData.prenostel_kraj || '');
      setPrenostelDavcna(narociloData.prenostel_davcna || '');
      setPrenostelPooblascen(narociloData.prenostel_pooblascen || '');
      setPrenostelPrekinitevDajalec(narociloData.prenostel_prekinitev_dajalec || '');
      setPrenostelOhranitevDajalec(narociloData.prenostel_ohranitev_dajalec || '');
      setPrenostelImeNarocnika(narociloData.prenostel_ime_narocnika || '');
      setPrenostelNarocniskaStUporIme(narociloData.prenostel_narocniska_st_upor_ime || '');
      setPrenostelPrekiniOhraniSirokopasovni(narociloData.prenostel_prekini_ohrani_sirokopasovni || '');
      setPrenostelOhraniTv(narociloData.prenostel_ohrani_tv || '');
      setPrenostelOhraniInternet(narociloData.prenostel_ohrani_internet || '');
      setPrenostelOhraniDomeno(narociloData.prenostel_ohrani_domeno || '');
      setPrenostelDomena(narociloData.prenostel_domena || '');
      setPrenostelOhraniEmail(narociloData.prenostel_ohrani_email || '');
      setPrenostelEmail(narociloData.prenostel_email || '');
      setTehnologija(narociloData.expand?.tehnologija || '');
      setPaketRel(narociloData.expand?.paket_rel || '');
      setPackageType(narociloData.expand?.paket_rel?.tip || '');
      setPaket(narociloData.expand?.paket_rel?.paket || '');
      setAkcijaRel(narociloData.expand?.akcija_rel || '');
      setPopust(narociloData.expand?.akcija_rel?.popust || '');
      setAttachments(narociloData.priloge || []);
      setOprema(narociloData.expand?.narocilododatnipaketi_via_narocilo || []);

      // Map the "narocilododatnipaketi_via_narocilo" to the expected structure
      const mappedOprema = (narociloData.expand?.narocilododatnipaketi_via_narocilo || []).map(pkg => ({
        id: pkg.id,
        paket: pkg.paket, // or pkg.paketId based on your actual data structure
        akcija: pkg.akcija, // or pkg.akcijaId based on your actual data structure
        saveStatus: 'existing', // Assuming these are existing packages
        tip: pkg.tip,
      }));
      setOprema(mappedOprema);

      // Set the gdprConsent state based on the data from the "narocilo" record
      // gdpr consent in: narociloData.expand.stranka.expand.gdpr
      // use the following field names for setGdprConsent: cb_1_1, cb_1_2, cb_1_3, cb_1_4, cb_1_5, cb_1_6, cb_1_7, cb_2_1, cb_2_2, cb_2_3, cb_2_4, cb_2_5, cb_2_6, cb_2_7, cb_3_1, cb_3_2, cb_3_3, cb_3_4, cb_3_5, cb_3_6, cb_3_7
      // ignore the rest of the fields
      const gdprConsentData = narociloData.expand?.stranka?.expand?.gdpr || [];

      // Update gdprConsent state with the data from the gdprConsentData object
      const newGdprConsent = { ...gdprConsent }; // Create a copy of the current gdprConsent state

      // Iterate over the gdprConsentData object and update the gdprConsent state
      for (const key in gdprConsentData) {
        if (gdprConsentData.hasOwnProperty(key) && key.startsWith('cb_')) {
          newGdprConsent[key] = gdprConsentData[key];
        }
      }
      // now also update the signed field
      newGdprConsent.signed = gdprConsentData.signed;

      setGdprConsent(newGdprConsent); // Update the state with the new GDPR consent values
      //setGdprSigned(gdprConsentData.signed); // Update the gdprSigned state with the value from the gdprConsentData object

    } catch (error) {
      console.error('Error fetching obiski record:', error);
    }
  };

  const initialLoad = useRef(true); // Track whether the component has just mounted

  const handlePersonSelect = (person, userInitiated) => {
    if (!userInitiated) {
      return;
    }
    if (initialLoad.current) {
      return;
    }
    setSelectedPerson(person);
    setFirstName(person.ime);
    setLastName(person.priimek);
    setTaxNumber(person.davcna_st);
    setStreet(person.ulica_naziv);
    setHisnaStevilka(person.hs_stevilka);
    setPostalCode(person.postni_okolis_sifra);
    setCity(person.postni_okolis_naziv);
    setFloorApartment(person.nadstropje);
    setContactPhone(person.kontakt_tel || ''); // Add default values if needed
    setEmail(person.email || ''); // Add default values if needed
    setGdprConsentData(person.expand?.gdpr || {}); // Set GDPR consent data
  };

  /*
  // Effect hook to fetch narociloData 
  // and set initial values if persons array is not empty
  useEffect(() => {
    if (narociloData && narociloData.id) {
      setNarociloData(narociloData);
      setNarociloId(narociloData.id);
    }
  }, [narociloData]);
*/

  // Effect hook to fetch narociloData 
  // and set initial values if persons array is not empty
  useEffect(() => {
    /*console.log('narociloData:', narociloData);
    console.log('persons:', persons);
    console.log('initialLoad:', initialLoad.current);*/

    if (initialLoad.current) {
      if (narociloData && narociloData.id) {
        //console.log('Obdelujem naročilo...');
        setNarociloData(narociloData);
        setNarociloId(narociloData.id);

        if (narociloData.davcna_st && persons.length > 0) {
          const person = persons.find(p => p.davcna_st === narociloData.davcna_st);
          if (person) {
            pb.collection('stranke').getOne(person.id, { expand: 'gdpr' }).then(person => {
              if (person) {
                setSelectedPerson(person);
                setNarociloPerson(person);
                setGdprConsentData(person.expand?.gdpr || {});

                // Replace the first element of the persons array with the newly fetched person
                const updatedPersons = [...persons];
                updatedPersons[0] = person;
                setPersons(updatedPersons);
              }
            });
          }
        }
      } else {

        if (persons.length > 0) {
          pb.collection('stranke').getOne(persons[0].id, { expand: 'gdpr' }).then(person => {
            setFirstName(person.ime);
            setLastName(person.priimek);
            setTaxNumber(person.davcna_st);
            setStreet(person.ulica_naziv);
            setHisnaStevilka(person.hs_stevilka);
            setPostalCode(person.postni_okolis_sifra);
            setCity(person.postni_okolis_naziv);
            setFloorApartment(person.nadstropje);
            setContactPhone(person.kontakt_tel || '');
            setEmail(person.email || '');
            setSelectedPerson(person);
            setIsLegalEntity(person.podjetje !== null && person.podjetje !== '');
            setPodjetje(person.podjetje || '');
            setZavezanecZaDDV(person.zavezanec_za_ddv === true);
            setMaticnaStPodjetje(person.maticna_st_podjetje || '');
            setGdprConsentData(person.expand?.gdpr || {});

            // Replace the first element of the persons array with the newly fetched person
            const updatedPersons = [...persons];
            updatedPersons[0] = person;
            setPersons(updatedPersons);
          });
        }
      }
      initialLoad.current = false; // Ensure this block only runs once
    }
  }, [narociloData, persons]);

  const setGdprConsentData = (gdprData) => {
    const newGdprConsent = { ...gdprConsent }; // Create a copy of the current gdprConsent state
    // Iterate over the gdprData object and update the gdprConsent state
    for (const key in gdprData) {
      if (gdprData.hasOwnProperty(key) && key.startsWith('cb_')) {
        newGdprConsent[key] = gdprData[key];
      }
    }
    newGdprConsent.signed = gdprData.signed;
    setGdprConsent(newGdprConsent); // Update the state with the new GDPR consent values
  };

  // Effect hook to fetch obiskiData
  useEffect(() => {
    setStreet(initialData?.ulica_naziv || '');
    setHisnaStevilka(initialData?.hs_stevilka || '');
    setPostalCode(initialData?.postni_okolis_sifra || '');
    setCity(initialData?.postni_okolis_naziv || '');
    setNaselje(initialData?.naselje_naziv || '');
  }, [initialData]);

  // tells me for each form if it is correctly filled or not
  // start with true - do not show the warning
  const [isForm1Correct, setIsForm1Correct] = useState(true);
  const [isForm2Correct, setIsForm2Correct] = useState(true);
  const [isForm3Correct, setIsForm3Correct] = useState(true);
  const [isForm4Correct, setIsForm4Correct] = useState(true);


  // array of required fields for each form
  const requiredFieldsForm0 = [
    firstName,
    lastName,
    taxNumber,
    contactPhone,
    //email,
    street,
    hisnaStevilka,
    postalCode,
    city,
    floorApartment
  ];

  const requiredFieldsForm1 = [
    paketRel
  ];

  const requiredFieldsForm2Mobile = [
    prenostelMsisdn1,
    prenostelDajalec,
    prenostelStRacuna
  ];

  const requiredFieldsForm2Fix = [
    prenostelMsisdn1,
    prenostelDajalec,
    prenostelStRacuna,
    prenostelNarocniskaStUporIme
  ];

  const requiredFieldsForm2DrugNarocnik = [
    prenostelImePodjetje,
    prenostelNaslov,
    prenostelPostnaSt,
    prenostelKraj,
    prenostelDavcna
  ];

  const requiredFieldsForm3 = [

  ];

  const checkForm1 = () => {
    if (isTaxNumberValid
      && (isMaticnaStValid || !isLegalEntity)
      && (!isLegalEntity || (podjetje !== '' && maticnaStPodjetje !== ''))
      && ((!email) || isEmailValid)
      && requiredFieldsForm0.every(field => field !== '')) {
      return true;
    } else {
      return false;
    }
  };

  const checkForm2 = () => {
    let form2Correct = true;
    let form3Correct = true;
    if (requiredFieldsForm1.every(field => field !== '')) {
      form2Correct = true;
    } else {
      form2Correct = false;
    }
    if (!numberTransfer) {
      form3Correct = true;
    }
    return { form2Correct, form3Correct };
  };

  const checkForm3 = () => {
    let form3Ok = true;
    if (numberTransfer) {
      if (isMobilePackage) {
        if (requiredFieldsForm2Mobile.every(field => field !== '')) {
          form3Ok = true;
        } else {
          form3Ok = false;
        }
      } else { // fiksna
        if (requiredFieldsForm2Fix.every(field => field !== '')) {
          form3Ok = true;
        } else {
          form3Ok = false;
        }
        if (prenostelOhraniEmail) {
          if (prenostelEmail && prenostelEmailValid) {
            form3Ok = true;
          } else {
            form3Ok = false;
          }
        }
      }
      if (drugNarocnik) {
        if (requiredFieldsForm2DrugNarocnik.every(field => field !== '')) {
          //form3Ok = true;
        } else {
          form3Ok = false;
        }
        if (!prenostelDavcnaValid) {
          form3Ok = false;
        }
      }
      if (!(prenostelPrekinitevDajalec || prenostelOhranitevDajalec)) {
        form3Ok = false;
      }
    } else {
      form3Ok = true;
    }
    return form3Ok;
  };

  const checkForm4 = () => {
    let form4Ok = true;
    if (requiredFieldsForm3.every(field => field !== '')) {
      form4Ok = true;
    } else {
      form4Ok = false;
    }
    if (racunEmail || racunSMS || racunPisno) {
      if (racunEmail) {
        if (racunEmailNaslov && racunEmailNaslovValid) {
          form4Ok = true;
        } else {
          form4Ok = false;
        }
        if (racunPisno) {
          if (racunPisnoUlica !== '' && racunPisnoPostnaSt !== '' && racunPisnoMesto !== '') {
            form4Ok = true;
          } else {
            form4Ok = false;
          }
        }
      }
    } else {
      form4Ok = false;
    }
    return form4Ok;
  };

  const checkCurrentFormFieldsFilled = () => {
    let form1Ok = isForm1Correct;
    let form2Ok = isForm2Correct;
    let form3Ok = isForm3Correct;
    let form4Ok = isForm4Correct;

    switch (currentForm) {
      case 0:
        form1Ok = checkForm1();
        setIsForm1Correct(form1Ok);
        break;
      case 1:
        const { form2Correct, form3Correct } = checkForm2();
        form2Ok = form2Correct;
        form3Ok = form3Correct;
        console.log('checkCurrentFormFieldsFilled: form2Ok:', form2Ok);
        console.log('checkCurrentFormFieldsFilled: form3Ok:', form3Ok);
        setIsForm2Correct(form2Ok);
        setIsForm3Correct(form3Ok);
        break;
      case 2:
        form3Ok = checkForm3();
        setIsForm3Correct(form3Ok);
        break;
      case 3:
        form4Ok = checkForm4();
        setIsForm4Correct(form4Ok);
        break;
      default:
        break
    }
    return form1Ok && form2Ok && form3Ok && form4Ok;
  };

  const checkAllFormsFilled = () => {
    let form3Ok = true;
    const form1Ok = checkForm1();
    const { form2Correct, form3Correct } = checkForm2();
    if (!numberTransfer) {
      form3Ok = checkForm3();
    }
    const form4Ok = checkForm4();

    setIsForm1Correct(form1Ok);
    setIsForm2Correct(form2Correct);
    setIsForm3Correct(form3Ok);
    setIsForm4Correct(form4Ok);

    return form1Ok && form2Correct && form3Ok && form4Ok;
  };

  const handleFormChange = (index) => {
    let formsValid = checkCurrentFormFieldsFilled();
    handleSave();

    // Check if the number transfer is selected and skip the number transfer form
    if (index === 2) {
      if (!numberTransfer) {
        index = 3;
      }
    }

    if (index === 5) { // GDPR form
      if (selectedPerson?.expand?.gdpr?.signed) {
        index = 6;
      }
    }

    // GDPR form
    //if (index === 5) {
    //  if 

    //console.log('isForm1Correct:', isForm1Correct);
    if (index === 6) {
      formsValid = checkAllFormsFilled();
      if (formsValid) {
        handleSignature();
      } else {
        return;
      }

    }
    setCurrentForm(index);
  };

  const handleCompleteSignature = (signedDocs) => {
    setSignedDocuments(signedDocs);
    setIsNarociloFinished(true);
    handleFormChange(7); // Move to the step for choosing recipients
  };

  const handlePrevious = () => {
    handleFormChange(currentForm - 1);
    //setCurrentForm(currentForm - 1);
  };

  const handleNext = async () => {
    console.log('Dispatching refetchPersons event');  // Debugging log
    const event = new CustomEvent('refetchPersons');
    window.dispatchEvent(event);
    handleFormChange(currentForm + 1);
  };

  const handleSave = async () => {
    const data = {
      teren: initialData?.id || '',
      agent: currentUserId, // current user's ID
      odziv: 'Prodano',
      opombe: notes ,
      datum: new Date().toISOString(), // Current date in ISO format
      davcna_st: taxNumber, // Tax number from state
      ime: firstName, // First name from state
      priimek: lastName, // Last name from state
      ulica: street,
      hisna_st: hisnaStevilka,
      postna_st: postalCode,
      posta: city,
      nadstropje_in_st_stanovanja: floorApartment, // Floor and apartment number from state
      st_za_prenos: landlinePhone, // Landline phone number from state
      kontakt_tel: contactPhone, // Contact phone number from state
      email: email, // Email from state
      //      paket: packageDetail, // Package detail from state
      cena_paketa_storitev: packagePrice, // Package price from state
      prenos_telefonske: numberTransfer, // Number transfer from state
      st_racuna_donorja_ali_puk: accountNumber, // Account number from state
      operater_donor: operator, // Operator from state
      narocnik: subscriber, // Subscriber from state
      st_narocnika_ali_uporabnisko_ime: subscriberNumberUsername, // Subscriber number or username from state
      storitve_za_prekinitev: servicesToTerminate, // Services to terminate from state
      is_prikljucnina: isConnectionFee, // Connection fee from state
      prikljucnina: connectionFee,
      ugodnost_ob_vezavi: benefitsBinding,
      paketStoritev: additionalServicesPackage,
      oprema: equipment,
      dodatne_storitve_interneta: additionalInternetServices,
      dodatne_storitve_telefonije: additionalPhoneServices,
      prenos_stevilke_soglasje: consentToTransfer,
      soglasje_za_prekinitev: consentToTerminate,
      placilo_s_trajnikom: standingOrderPayment,
      racun_sms: racunSMS,
      racun_email: racunEmail,
      racun_email_naslov: racunEmailNaslov,
      racun_banka: racunBanka,
      racun_banka_iban: racunBankaIBAN,
      racun_portal: racunPortal,
      racun_pisno: racunPisno,
      racun_pisno_ulica: racunPisnoUlica,
      racun_pisno_postna_st: racunPisnoPostnaSt,
      racun_pisno_mesto: racunPisnoMesto,
      podjetje: podjetje,
      zavezanec_za_ddv: zavezanecZaDDV,
      maticna_st_podjetje: maticnaStPodjetje,
      predcasna_aktivacija: predcasnaAktivacija,
      osebni_dokument: osebniDokument,
      //      popust: popust,
      popust_vezava: popustVezava,
      mobilni_paket: mobilniPaket,
      prenos_telefonska_st: prenosTelefonskaSt,
      prenos_ime: prenosIme,
      prenos_naslov: prenosNaslov,
      prenos_postna_kraj: prenosPostnaKraj,
      prenos_davcna: prenosDavcna,
      prenostel_msisdn1: prenostelMsisdn1,
      prenostel_puk1a: prenostelPuk1a,
      prenostel_msisdn2: prenostelMsisdn2,
      prenostel_puk2a: prenostelPuk2a,
      prenostel_st_racuna: prenostelStRacuna,
      prenostel_dajalec: prenostelDajalec,
      prenostel_ime_podjetje: prenostelImePodjetje,
      prenostel_naslov: prenostelNaslov,
      prenostel_postna_st: prenostelPostnaSt,
      prenostel_kraj: prenostelKraj,
      prenostel_davcna: prenostelDavcna,
      prenostel_pooblascen: prenostelPooblascen,
      prenostel_prekinitev_dajalec: prenostelPrekinitevDajalec,
      prenostel_ohranitev_dajalec: prenostelOhranitevDajalec,
      prenostel_ime_narocnika: prenostelImeNarocnika,
      prenostel_narocniska_st_upor_ime: prenostelNarocniskaStUporIme,
      prenostel_prekini_ohrani_sirokopasovni: prenostelPrekiniOhraniSirokopasovni,
      prenostel_ohrani_tv: prenostelOhraniTv,
      prenostel_ohrani_internet: prenostelOhraniInternet,
      prenostel_ohrani_domeno: prenostelOhraniDomeno,
      prenostel_domena: prenostelDomena,
      prenostel_ohrani_email: prenostelOhraniEmail,
      prenostel_email: prenostelEmail,
      naselje: naselje,
      obisk: obiskiId,
      tehnologija: tehnologija?.id,
      paket_rel: paketRel?.id,
      paket: paketRel?.paket,
      tip: paketRel?.tip,
      akcija_rel: akcijaRel?.id,
      popust: popust,
      esim: esim,
      mob_fix: mobFix,
      zekom: zekom,
      //stranka: stranka,
    };

    
    const personData = {
      ime: firstName,
      priimek: lastName,
      davcna_st: taxNumber,
      ulica: street,
      hisna_st: hisnaStevilka,
      postna_st: postalCode,
      posta: city,
      kontakt_tel: contactPhone,
      email: email,
      podjetje: podjetje,
      zavezanec_za_ddv: zavezanecZaDDV,
      maticna_st_podjetje: maticnaStPodjetje,
    };

    try {
      console.log('Data to save:', data);
      let result;
      result = await createNarociloRecord(pb, data, gdprConsent, gdprChanged, setGdprChanged, oprema, obiskiId, narociloId);
      setNarociloId(result.data.id);

      let updatedPerson;
      if (result.newStranka) {
        // New person created
        updatedPerson = result.newStranka;
        setPersons((prevPersons) => [...prevPersons, updatedPerson]);
        setNarociloPerson(updatedPerson);
        setSelectedPerson(updatedPerson);
        setGdprConsentData(result.newStranka.expand?.gdpr || {});
      } else if (result.updatedStranka) {
        // Existing person updated
        updatedPerson = result.updatedStranka;
        setPersons((prevPersons) =>
          prevPersons.map((person) =>
            person.davcna_st === taxNumber ? updatedPerson : person
          )
        );
        setNarociloPerson(updatedPerson);
        setSelectedPerson(updatedPerson);
      } else {
        // No new or updated person returned, update existing person with current data
        updatedPerson = persons.find((person) => person.davcna_st === taxNumber);
        if (updatedPerson) {
          updatedPerson = { ...updatedPerson, ...personData };
          setPersons((prevPersons) =>
            prevPersons.map((person) =>
              person.davcna_st === taxNumber ? updatedPerson : person
            )
          );
          setNarociloPerson(updatedPerson);
          setSelectedPerson(updatedPerson);
        }
      }
  
      // Update the persons state in SalePage using the callback
      if (updatedPerson) {
        updatePersonsAfterSave(updatedPerson);
      }
  
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false); // Hide the toast after a few seconds
      }, 3000); // 3000 ms = 3 seconds

      console.log('Record created successfully');
    } catch (error) {
      console.error('Error creating record:', error);
    }
  };

  // Initialize with one hardcoded attachment
  const [attachments, setAttachments] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false); // state to control modal visibility

  // Function to handle adding new attachments (for future implementation)
  const addAttachment = (newAttachment) => {
    setAttachments([...attachments, newAttachment]);
  };

  // Handles row button click to display modal
  const handleRowButtonClick = (rowData) => {
    setIsModalOpen(true);
  };


  // State and function for camera modal
  const [isButtonModalOpen, setIsButtonModalOpen] = useState(false); // state to control button modal visibility
  const handleButtonModalOpen = () => {
    setIsButtonModalOpen(true);
  };




  const [photo, setPhoto] = useState(null);

  const handleCapture = useCallback((capturedPhoto) => {
    setPhoto(capturedPhoto);
  }, []);

  const handleSubmitPhoto = useCallback(async () => {
    const formData = new FormData();
    const blob = await fetch(photo).then(res => res.blob());
    formData.append('priloge', blob, 'captured-image.jpg');
    formData.append('id', narociloId); // Assuming `obiskiId` is available in your context

    try {
      const createdRecord = await pb.collection('narocilo').update(narociloId, formData);
      setAttachments(createdRecord.priloge || []);
      console.log('Image uploaded successfully:', createdRecord);
    } catch (error) {
      console.error('Error uploading image:', error);
    }

    setIsButtonModalOpen(false); // Close the modal after upload
  }, [photo]);


  // voice recognizer functions

  const personalDataExtractor = useMemo(() => new PersonalDataExtractor(), []);

  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [transcript, setTranscript] = useState('');
  const recordingDebug = useRef(false);
  const [lastProcessedTranscript, setLastProcessedTranscript] = useState('');
  const [speechError, setSpeechError] = useState(null);

  const handleRecognizedIntents = (results) => {
    //console.log('Handling recognized intents:', results);
    results.forEach(({ intent, value }) => {
      //console.log(`Updating state for intent: ${intent}, value: ${value}`);
      switch (intent) {
        case 'firstName':
          setFirstName(value);
          break;
        case 'lastName':
          setLastName(value);
          break;
        case 'taxNumber':
          handleTaxNumberChange(value);
          break;
        case 'streetName':
          setStreet(value);
          break;
        case 'houseNumber':
          setHisnaStevilka(value);
          break;
        case 'postalCode':
          setPostalCode(value);
          break;
        case 'city':
          setCity(value);
          break;
        case 'phone':
          setContactPhone(value);
          break;
        case 'email':
          handleEmailChange(value);
          break;
        case 'isLegalEntity':
          handleLegalEntityChange(value === 'true' ? 'legal' : 'individual');
          break;
        case 'note':
          setNotes(value);
          break;
        case 'next':
          if (value === 'true') {
            handleNext();
          }
          break;
      }
    });
    //console.log('Finished processing intents');
  };

  const processTranscript = useCallback((transcriptToProcess, isFinal) => {
    if (transcriptToProcess && transcriptToProcess !== lastProcessedTranscript) {
      //console.log(`Processing ${isFinal ? 'final' : 'interim'} transcript:`, transcriptToProcess);
      const results = personalDataExtractor.recognize(transcriptToProcess);
      //console.log('Recognition results:', results);
      handleRecognizedIntents(results);

      if (isFinal) {
        setLastProcessedTranscript(transcriptToProcess);
      }
    }
  }, [handleRecognizedIntents, lastProcessedTranscript, personalDataExtractor]);

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window)) {
      console.error('Speech recognition not supported');
      return;
    }
    //if ('webkitSpeechRecognition' in window) {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'sl-SI';

    let finalTranscript = '';
    let resetTimer;

    const resetTranscriptAfterDelay = () => {
      clearTimeout(resetTimer);
      resetTimer = setTimeout(() => {
        finalTranscript = '';
        setTranscript('');
        setLastProcessedTranscript('');
      }, 3500); // 3.5 seconds
    };

    recognition.onresult = (event) => {
      clearTimeout(resetTimer); // Clear the timer on new results
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += (finalTranscript ? ' ' : '') + transcript;
        } else {
          interimTranscript += transcript;
        }
      }

      const currentTranscript = finalTranscript + (finalTranscript && interimTranscript ? ' ' : '') + interimTranscript;
      setTranscript(currentTranscript);

      // Process the concatenated transcript
      processTranscript(currentTranscript, false);

      // If we have a final result, process it separately
      if (finalTranscript !== '') {
        processTranscript(finalTranscript, true);
        setLastProcessedTranscript(finalTranscript);
      }

      resetTranscriptAfterDelay(); // Start the timer after processing
    };


    /*
    recognition.onresult = (event) => {
      //console.log('Speech recognition result received');
      let interimTranscript = '';
      let finalTranscript = '';
 
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      //console.log('Interim transcript:', interimTranscript);
      //console.log('Final transcript:', finalTranscript);
 
      //setTranscript(interimTranscript || finalTranscript);

      if (interimTranscript) {
        setTranscript(interimTranscript);
        processTranscript(interimTranscript, false);
      }
 
      if (finalTranscript) {
        //setTranscript(finalTranscript);
        //processTranscript(finalTranscript, true);
      }

    };*/


    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
      setSpeechError(event.error);

      if (event.error === 'network') {
        // Attempt to restart recognition after a short delay
        setTimeout(() => {
          if (isListening) {
            recognition.stop();
            recognition.start();
          }
        }, 5000); // Wait for 5 seconds before attempting to restart
      }
      clearTimeout(resetTimer); // Clear the timer on error
    };

    recognition.onend = () => {
      if (isListening) {
        recognition.start();
      } else {
        // Reset the final transcript when stopping
        finalTranscript = '';
        clearTimeout(resetTimer); // Clear the timer when stopping
      }
    };

    setRecognition(recognition);
    //return () => {
    //  recognition.abort();
    //};
    return () => {
      clearTimeout(resetTimer); // Clean up the timer when the component unmounts
    };

  }, []);

  const toggleListening = () => {
    if (isListening) {
      recognition.stop();
      setSpeechError(null); // Clear any previous errors
      setTranscript(''); // Clear the transcript when starting to listen
      setLastProcessedTranscript(''); // Clear the last processed transcript
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  const [isDocumentSent, setIsDocumentSent] = useState(false);

  const handleSendDocuments = async () => {
    setIsDocsLoading(true);
    const formData = new FormData();

    signedDocuments.forEach((doc, index) => {
      formData.append(`pdf_${index}`, doc.pdfBlob, `document_${index}.pdf`);
      formData.append(`form_selection_${index}`, doc.documentType);

      doc.signatures.forEach((sig, sigIndex) => {
        formData.append(`signature_${index}_${sigIndex}`, sig.blob, `${sig.key}.png`);
      });
    });

    // Add other form data (agent, customer info, etc.)
    formData.append('agent_name', currentUserFullName);
    formData.append('agent_phonenum', currentUserPhoneNum);
    formData.append('agent_email', currentUserEmail);
    formData.append('ime_priimek_stranke', `${firstName} ${lastName}`);
    formData.append('podjetje', podjetje);
    formData.append('davcna', taxNumber);
    formData.append('packet_name', `${paketRel.paket} ${akcijaRel ? `(${akcijaRel.popust})` : ''}`);
    formData.append('technology', tehnologija ? tehnologija.tehnologija : '');
    formData.append('is_test_system', isTestDatabase);
    formData.append('notes', notes + (podjetje ? ` (Zastopnik: ${firstName} ${lastName})` : '') + (zekom && numberTransfer ? ' Prekinitev po ZEKom' : ''));

    if (akcijaRel) {
      formData.append('odobren_popust', `Odobril: ${currentUserFullName}`);
    }

    formData.append('kontakt_telefon', contactPhone);

    const opremaInfo = oprema
      .filter(item => item.saveStatus !== "deleted")
      .map(item => {
        const packageName = allPackages.find(pkg => pkg.id === item.paket)?.paket || '';
        const akcijaName = allAkcije.find(akcija => akcija.id === item.akcija)?.popust || '';
        return `${packageName}${akcijaName ? ` (${akcijaName})` : ''}`;
      })
      .join(', ');
    formData.append('oprema_info', opremaInfo);

    formData.append('to_emails', currentUserEmail);  //agent's email, works this email gets sent
    if (sendToClient) {
      console.log('Sending documents to client:', email); //logs correct users email
      formData.append('client_email', email); // client's email
    } else {
      console.log('Not sending documents to client');
    }

    formData.append('orderID', narociloId);
    formData.append('is_test_system', isTestDatabase);

    try {
      const response = await fetch(config.signatureServiceUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let result;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        result = await response.json();
      } else {
        result = await response.text();
      }

      console.log('Documents sent successfully:', result);
      setIsDocumentSent(true);
      handleFormChange(8);
    } catch (error) {
      console.error('Error sending documents:', error);
    } finally {
      setIsDocsLoading(false);
    }
  };

  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const disableMicrophone = true; // Disable the microphone button for now

  useEffect(() => {
    if (typeof racunEmail === 'boolean' ? racunEmail : racunEmail === 'true') {
      setRacunEmailNaslov(email);
    }
  }, [racunEmail, email]);

  useEffect(() => {
    if (typeof racunPisno === 'boolean' ? racunPisno : racunPisno === 'true') {
      setRacunPisnoUlica(`${street || ''} ${hisnaStevilka || ''}`);
      setRacunPisnoPostnaSt(postalCode || '');
      setRacunPisnoMesto(city || '');
    }
  }, [racunPisno, street, hisnaStevilka, postalCode, city]);

  useEffect(() => {
    if (
      prenostelImePodjetje ||
      prenostelNaslov ||
      prenostelPostnaSt ||
      prenostelKraj ||
      prenostelDavcna
    ) {
      setDrugNarocnik(true);
    } else {
      setDrugNarocnik(false);
    }
  }, [prenostelImePodjetje, prenostelNaslov, prenostelPostnaSt, prenostelKraj, prenostelDavcna]);

  const handleCheckboxChange = (checked) => {
    if (!checked) {
      // Clear all related fields
      setPrenostelImePodjetje('');
      setPrenostelNaslov('');
      setPrenostelPostnaSt('');
      setPrenostelKraj('');
      setPrenostelDavcna('');
    }
    setDrugNarocnik(checked);
  };



  useEffect(() => {
    const checkLegalEntityFields = () => {
      if (podjetje || maticnaStPodjetje || zavezanecZaDDV) {
        setIsLegalEntity(true);
      }
    };

    checkLegalEntityFields();
  }, [podjetje, maticnaStPodjetje, zavezanecZaDDV]);

  const handleRadioButtonChange = (value) => {
    if (value === 'individual') {
      // Clear all legal entity fields
      setPodjetje('');
      setMaticnaStPodjetje('');
      setZavezanecZaDDV(false);
    }
    setIsLegalEntity(value === 'legal');
    handleLegalEntityChange(value);
  };


  useEffect(() => {
    setIsMobilePackage(packageType === 'mobile');
  }, [packageType]);


  // Main component return
  // For the address fields, initialize with initialData if it exists, otherwise initialize with empty string
  return (
    <>
      <div className="sale-page-container">
        <div className="progress-indicator-container">
          <ProgressIndicator currentIndex={currentForm} onChange={handleFormChange} vertical={false}>
            <ProgressStep label="Naročnik" description="Osnovni podatki o naročniku" invalid={!isForm1Correct} />
            <ProgressStep label="Storitve" description="Prikjuček in storitve" invalid={!isForm2Correct} />
            <ProgressStep label="Prenos" description="Prenos priključka" disabled={!numberTransfer} invalid={!isForm3Correct} />
            <ProgressStep label="Računi" description="Prejemanje računov" invalid={!isForm4Correct} />
            <ProgressStep label="Priloge" description="Attachments and documents" />
            <ProgressStep label="GDPR" description="GDPR" disabled={selectedPerson?.expand?.gdpr?.signed} />
            <ProgressStep label="Podpis" description="Signature and submission" />
            <ProgressStep label="Zaključek" description="Naročilo je zaključeno" disabled={true}/>
          </ProgressIndicator>
        </div>
        <div className="form-container">
          {currentForm === 0 && (
            <Form>
              <h2 className="section-title">Naročnik</h2>
              <FormGroup legendText="">
                <div className="form-row">
                  <div className="form-half-width" style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <RadioButtonGroup
                      name="entityType"
                      onChange={(value) => handleRadioButtonChange(value)}
                      valueSelected={isLegalEntity ? 'legal' : 'individual'}
                    >
                      <RadioButton
                        labelText="Fizična oseba"
                        value="individual"
                      />
                      <RadioButton
                        labelText="Pravna oseba"
                        value="legal"
                      />
                    </RadioButtonGroup>
                  </div>
                  <div className="form-half-width" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {persons && persons.length > 0 && (
                      <div className="dropdown-wrapper">
                        <div className="dropdown-container">
                          <Dropdown
                            id="person-dropdown"
                            titleText="Izberite osebo"
                            label="Izberite osebo"
                            items={persons}
                            itemToString={(item) => {
                              if (!item) {
                                return '';
                              } else if (item.podjetje) {
                                return `${item.podjetje} (${item.davcna_st})`;
                              } else {
                                return `${item.ime} ${item.priimek} (${item.davcna_st})`;
                              }
                            }}
                            onChange={({ selectedItem }) => handlePersonSelect(selectedItem, true)}
                            selectedItem={selectedPerson}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div className="form-row">
                  <TextInput
                    labelText="Ime"
                    id="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Janez"
                  />
                  <TextInput
                    labelText="Priimek"
                    id="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Novak"
                  />
                </div>
                {isLegalEntity && (
                  <div>
                    <div className="form-row">
                      <TextInput
                        labelText="Zakoniti zastopnik družbe"
                        id="podjetje"
                        value={podjetje}
                        onChange={(e) => setPodjetje(e.target.value)}
                        placeholder="Podjetje d.o.o."
                      />
                      <TextInput
                        labelText="Matična številka (za pravne osebe)"
                        id="maticna-st-podjetje"
                        value={maticnaStPodjetje}
                        onChange={(e) => handleMaticnaStPodjetjeChange(e.target.value)}
                        placeholder="1234567000"
                        invalid={!isMaticnaStValid}
                        invalidText="Matična številka ni veljavna"
                      />
                    </div>
                    <Checkbox
                      labelText="Zavezanec za DDV"
                      id={`zavezanec-za-ddv-${narociloId}`}
                      checked={typeof zavezanecZaDDV === 'boolean' ? zavezanecZaDDV : zavezanecZaDDV === 'true'}
                      onChange={(e) => setZavezanecZaDDV(e.target.checked)}
                    />
                  </div>
                )}
                <div className="form-row">
                  <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                    <TextInput
                      className="form-two-thirds-width"
                      labelText="Ulica, hišna številka"
                      id="address"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      placeholder="Slovenska cesta"
                    />
                    <TextInput
                      className="form-third-width"
                      labelText="Hišna številla"
                      id="house-number"
                      value={hisnaStevilka}
                      onChange={(e) => setHisnaStevilka(e.target.value)}
                      placeholder='1a'
                    />
                  </div>
                  <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                    <TextInput
                      className="form-third-width"
                      labelText="Poštna številka"
                      id="postal-code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="1000"
                    />
                    <TextInput
                      className="form-two-thirds-width"
                      labelText="Pošta"
                      id="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Ljubljana"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <TextInput
                    labelText="Nadstropje in št. stanovanja"
                    id="floor-apartment"
                    value={floorApartment}
                    onChange={(e) => setFloorApartment(e.target.value)}
                    placeholder="4/1 ali HIŠA"
                  />
                  <TextInput
                    labelText="Davčna številka"
                    id="tax-number"
                    value={taxNumber}
                    onChange={(e) => handleTaxNumberChange(e.target.value)}
                    placeholder="12345678"
                    invalid={!isTaxNumberValid}
                    invalidText="Davčna številka ni veljavna"
                  />
                </div>
                <div className="form-row">
                  <TextInput
                    labelText="Kontaktni telefon"
                    id="contact-phone"
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                    placeholder="070 700 700"
                  />
                  <TextInput
                    labelText="E-mail naslov"
                    id="email"
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    placeholder="janez.novak@gmail.com"
                    invalid={!isEmailValid}
                    invalidText="Vnesite veljaven e-mail naslov"
                  />
                </div>
                <TextInput
                  labelText="Opombe"
                  id="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="stac. tel. GRATIS; stranka je dosegljiva le dopoldan"
                />
              </FormGroup>
            </Form>
          )}
          {currentForm === 1 && (
            <Form>
              <h2 className="section-title">Priključek in storitve</h2>
              <br />
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <div className="form-quarter-width">
                    <RadioButtonGroup
                      name="packageType"
                      onChange={(value) => handlePackageTypeChange(value)}
                      valueSelected={packageType}
                    >
                      <RadioButton labelText="Fiksni paket" value="fixed" />
                      <RadioButton labelText="Mobilni paket" value="mobile" />
                    </RadioButtonGroup>
                  </div>
                  <div className="form-quarter-width">
                    <Checkbox
                      labelText="Zahtevek za prenos"
                      id={`number-transfer-${narociloId}`}
                      checked={typeof numberTransfer === 'boolean' ? numberTransfer : numberTransfer === 'true'}
                      onChange={(e) => {
                        setNumberTransfer(e.target.checked);
                        if (!e.target.checked) {
                          setPrenosTelefonskaSt('');
                          setOperator('');
                          setAccountNumber('');
                          setPrenosIme('');
                          setPrenosNaslov('');
                          setPrenosPostnaKraj('');
                          setPrenosDavcna('');
                        } else {
                          setPrenosTelefonskaSt(contactPhone || '');
                          setPrenosIme(`${firstName || ''} ${lastName || ''}`);
                          setPrenosNaslov(`${street || ''} ${hisnaStevilka || ''}`);
                          setPrenosPostnaKraj(`${postalCode || ''} ${city || ''}`);
                          setPrenosDavcna(taxNumber || '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <div className="dropdown-wrapper">
                    <div className="dropdown-container">
                      {packageType === 'fixed' && (
                        <Dropdown
                          titleText="Tehnologija"
                          id="tehnologija-dropdown"
                          label="Izberite tehnologijo"
                          items={tehnologije}
                          itemToString={(item) => item.tehnologija}
                          selectedItem={tehnologija}
                          onChange={({ selectedItem }) => handleTehnologijaChange(selectedItem)}
                        />
                      )}
                      {packageType === 'mobile' && (
                        <Checkbox
                          labelText="eSIM"
                          id={`esim-${narociloId}`}
                          checked={typeof esim === 'boolean' ? esim : esim === 'true'}
                          onChange={(e) => setEsim(e.target.checked)}
                        />
                      )}
                    </div>
                    <div className="dropdown-container">
                      {packageType === 'mobile' && (
                        <Checkbox
                          labelText="MOB/FIX"
                          id={`mobFix-${narociloId}`}
                          checked={typeof mobFix === 'boolean' ? mobFix : mobFix === 'true'}
                          onChange={(e) => setMobFix(e.target.checked)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown-wrapper">
                <div className="dropdown-container">
                  <Dropdown
                    titleText="Paket storitev"
                    id="package-dropdown"
                    label="Paket storitev"
                    items={
                      packageType === 'mobile'
                        ? allPackages.filter(pkg => pkg.tip === 'mobile')
                        : allPackages.filter(pkg => pkg.tehnologija === tehnologija.id)
                    }
                    itemToString={(item) =>
                      item.paket ? (item.cena === 0 ? `${item.paket}` : `${item.paket} (Cena: ${item.cena} EUR)`) : ''
                    }
                    selectedItem={paketRel}
                    onChange={({ selectedItem }) => {
                      setPopust(null); // Clear the "Akcija" selection whenever the package changes
                      setAkcijaRel(null);
                      setPaketRel(selectedItem);
                      if (selectedItem) {
                        setPaket(selectedItem.paket);
                        setPackagePrice(selectedItem.cena);
                        setPackageType(selectedItem.tip);
                      } else {
                        setPaket('');
                        setPackagePrice('');
                      }
                    }}
                  />
                </div>
                <div className="dropdown-container">
                  <Dropdown
                    titleText="Akcija"
                    id="popust-dropdown"
                    label="Akcija"
                    items={allAkcije.filter(akcija => akcija.paket === paketRel.id)}
                    itemToString={(item) => item ? `${item.popust}` : ''}
                    selectedItem={akcijaRel}
                    onChange={({ selectedItem }) => {
                      setAkcijaRel(selectedItem);
                      setPopust(selectedItem.popust)
                    }}
                  />
                </div>
              </div>

              {packageType === 'fixed' && (
                <>
                  <div className="additional-packages">
                    <h3>Dodatni paketi</h3>
                    {oprema
                      .filter(item => item.saveStatus !== "deleted")
                      .filter(item => item.tip === 'additional')
                      .map((pkg) => (
                        <div key={pkg.id} className="additional-package">
                          <div className="dropdown-wrapper">
                            <div className="dropdown-container">
                              <Dropdown
                                id={`package-select-${pkg.id}`}
                                label="Izberi paket"
                                items={additionalPackageList}
                                itemToString={(item) => (item ? `${item.paket}` : '')}
                                selectedItem={additionalPackageList.find(item => item.id === pkg.paket)}
                                onChange={(e) => updateOpremaSelection(pkg.id, e.selectedItem.id)}
                              />
                            </div>
                            <div className="dropdown-container">
                              <Dropdown
                                id={`akcija-select-${pkg.id}`}
                                label="Izberi akcijo"
                                items={allAkcije.filter(akcija => akcija.paket === pkg.paket)}
                                itemToString={(item) => (item ? item.popust : '')}
                                selectedItem={allAkcije.find(item => item.id === pkg.akcija) || null}
                                onChange={(e) => updateOpremaAkcijaSelection(pkg.id, e.selectedItem.id)}
                              />
                            </div>
                          </div>
                          <div className="button-container">
                            <Button
                              kind="danger"
                              size="md"
                              renderIcon={TrashCan}
                              hasIconOnly
                              onClick={() => removeOprema(pkg.id)}
                            >
                              Odstrani
                            </Button>
                          </div>
                        </div>
                      ))}
                    <div className="button-container">
                      <Button
                        kind="primary"
                        renderIcon={Add}
                        onClick={() => addOprema('additional')}
                        size='md'
                      >
                        Dodaj paket
                      </Button>
                    </div>
                  </div>
                  <div className="additional-packages">
                    <h3>Oprema</h3>
                    {oprema
                      .filter(item => item.saveStatus !== "deleted")
                      .filter(item => item.tip === 'opremafix')
                      .map((item) => (
                        <div key={item.id} className="additional-package">
                          <div className="dropdown-wrapper">
                            <div className="dropdown-container">
                              <Dropdown
                                id={`oprema-select-${item.id}`}
                                label="Izberi opremo"
                                items={equipmentList}
                                itemToString={(equip) => (equip ? equip.paket : '')}
                                selectedItem={equipmentList.find(equip => equip.id === item.paket)}
                                onChange={(e) => updateOpremaSelection(item.id, e.selectedItem.id)}
                              />
                            </div>
                            <div className="dropdown-container">
                              <Dropdown
                                id={`oprema-akcija-select-${item.id}`}
                                label="Izberi akcijo"
                                items={allAkcije.filter(akcija => akcija.paket === item.paket)}
                                itemToString={(akcija) => (akcija ? akcija.popust : '')}
                                selectedItem={allAkcije.find(akcija => akcija.id === item.akcija)}
                                onChange={(e) => updateOpremaAkcijaSelection(item.id, e.selectedItem.id)}
                              />
                            </div>
                          </div>
                          <div className="button-container">
                            <Button kind="danger" renderIcon={TrashCan} hasIconOnly size="md" onClick={() => removeOprema(item.id)}>
                              Odstrani
                            </Button>
                          </div>
                        </div>
                      ))}
                    <div className="button-container">
                      <Button
                        kind="primary"
                        renderIcon={Add}
                        size="md"
                        onClick={() => addOprema('opremafix')}>
                        Dodaj opremo
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>

          )}
          {currentForm === 2 && isMobilePackage && numberTransfer && (
            <Form>
              <h2 className="section-title">Prenos mobilne številke</h2>
              <div className="form-row">
                <TextInput
                  labelText="Telefonska številka za prenos"
                  id="prenostel_msisdn1"
                  value={prenostelMsisdn1}
                  onChange={(e) => setPrenostelMsisdn1(e.target.value)}
                  placeholder="070 700 700"
                />
                <TextInput
                  labelText="Operater (donor)"
                  id="prenostel_dajalec"
                  value={prenostelDajalec}
                  onChange={(e) => setPrenostelDajalec(e.target.value)}
                  placeholder="Telekom / A1 / T2 / BOB / Hofer / re:do /SPAR / idr."
                />
              </div>
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <TextInput
                    labelText="Številka računa pri operaterju (donorju)"
                    id="prenostel_st_racuna"
                    value={prenostelStRacuna}
                    onChange={(e) => setPrenostelStRacuna(e.target.value)}
                    placeholder="123456789"
                  />
                </div>
                <div className="form-half-width" style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  <Checkbox
                    className="form-quarter-width"
                    labelText="Prekinitev razmerja pri donorju številke"
                    id={`prenostel_prekinitev_dajalec-${narociloId}`}
                    checked={typeof prenostelPrekinitevDajalec === 'boolean' ? prenostelPrekinitevDajalec : prenostelPrekinitevDajalec === 'true'}
                    onChange={(e) => setPrenostelPrekinitevDajalec(e.target.checked)}
                  />
                  <Checkbox
                    className="form-quarter-width"
                    labelText="Ohranitev razmerja pri donorju"
                    id={`prenostel_ohranitev_dajalec-${narociloId}`}
                    checked={typeof prenostelOhranitevDajalec === 'boolean' ? prenostelOhranitevDajalec : prenostelOhranitevDajalec === 'true'}
                    onChange={(e) => setPrenostelOhranitevDajalec(e.target.checked)}
                  />
                  <Checkbox
                          labelText="Prekinitev po ZEKom"
                          id={`zekom-${narociloId}`}
                          checked={typeof zekom === 'boolean' ? zekom : zekom === 'true'}
                          onChange={(e) => setzekom(e.target.checked)}
                  />
                </div>
              </div>
              <Checkbox

                labelText="Naročnik je druga oseba"

                id={`prenostel_druga_oseba-${narociloId}`}

                checked={drugNarocnik}

                onChange={(e) => handleCheckboxChange(e.target.checked)}

              />
              {drugNarocnik && (
                <>
                  <div className='form-row'>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Ime in priimek/naziv podjetja"
                        id="prenostel_ime_podjetje"
                        value={prenostelImePodjetje}
                        onChange={(e) => setPrenostelImePodjetje(e.target.value)}
                      />
                    </div>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Naslov stalnega bivališča/sedež podjetja"
                        id="prenostel_naslov"
                        value={prenostelNaslov}
                        onChange={(e) => setPrenostelNaslov(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        className="form-third-width"
                        labelText="Poštna št."
                        id="prenostel_postna_st"
                        value={prenostelPostnaSt}
                        onChange={(e) => setPrenostelPostnaSt(e.target.value)}
                      />
                      <TextInput
                        className="form-two-thirds-width"
                        labelText="Kraj"
                        id="prenostel_kraj"
                        value={prenostelKraj}
                        onChange={(e) => setPrenostelKraj(e.target.value)}
                      />
                    </div>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Davčna številka"
                        id="prenostel_davcna"
                        value={prenostelDavcna}
                        onChange={(e) => handlePrenosDavcnaChange(e.target.value)}
                        invalid={!prenostelDavcnaValid}
                        invalidText="Vnesite veljavno davčno številko"
                      />
                    </div>
                  </div>
                </>
              )}
              <br />
            </Form>
          )}
          {(currentForm === 2 && !isMobilePackage) && numberTransfer && (
            <Form>
              <h2 className="section-title">Prenos telefonske številke ali XDSL/FTTH/OŠO priključka</h2>
              <div className="form-row">
                <TextInput
                  labelText="Telefonska številka za prenos"
                  id="prenostel_msisdn1"
                  value={prenostelMsisdn1}
                  onChange={(e) => setPrenostelMsisdn1(e.target.value)}
                />
                <TextInput
                  labelText="Operater (donor)"
                  id="prenostel_dajalec"
                  value={prenostelDajalec}
                  onChange={(e) => setPrenostelDajalec(e.target.value)}
                />
              </div>
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <TextInput
                    labelText="Številka računa pri operaterju (donorju))"
                    id="prenostel_st_racuna"
                    value={prenostelStRacuna}
                    onChange={(e) => setPrenostelStRacuna(e.target.value)}
                  />
                </div>
                <div className="form-half-width" style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  <Checkbox
                    className="form-quarter-width"
                    labelText="Prekinitev razmerja pri donorju številke"
                    id={`prenostel_prekinitev_dajalec-${narociloId}`}
                    checked={typeof prenostelPrekinitevDajalec === 'boolean' ? prenostelPrekinitevDajalec : prenostelPrekinitevDajalec === 'true'}
                    onChange={(e) => setPrenostelPrekinitevDajalec(e.target.checked)}
                  />
                  <Checkbox
                    className="form-quarter-width"
                    labelText="Ohranitev razmerja pri donorju"
                    id={`prenostel_ohranitev_dajalec-${narociloId}`}
                    checked={typeof prenostelOhranitevDajalec === 'boolean' ? prenostelOhranitevDajalec : prenostelOhranitevDajalec === 'true'}
                    onChange={(e) => setPrenostelOhranitevDajalec(e.target.checked)}
                  />
                  <Checkbox
                          labelText="Prekinitev po ZEKom"
                          id={`zekom-${narociloId}`}
                          checked={typeof zekom === 'boolean' ? zekom : zekom === 'true'}
                          onChange={(e) => setzekom(e.target.checked)}
                  />
                </div>
              </div>
              <br />
              <TextInput
                labelText="Naročnik širokopasovnih storitev (naročniška št./uporabniško ime)"
                id="prenostel_narocniska_st_upor_ime"
                value={prenostelNarocniskaStUporIme}
                onChange={(e) => setPrenostelNarocniskaStUporIme(e.target.value)}
              />
              <h3 className="section-title">Obseg ohranitve razmerja</h3>
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <Checkbox
                    className='form-quarter-width'
                    labelText="TV"
                    id={`prenostel_ohrani_tv-${narociloId}`}
                    checked={typeof prenostelOhraniTv === 'boolean' ? prenostelOhraniTv : prenostelOhraniTv === 'true'}
                    onChange={(e) => setPrenostelOhraniTv(e.target.checked)}
                  />
                  <Checkbox
                    className='form-quarter-width'
                    labelText="Internet"
                    id={`prenostel_ohrani_internet-${narociloId}`}
                    checked={typeof prenostelOhraniInternet === 'boolean' ? prenostelOhraniInternet : prenostelOhraniInternet === 'true'}
                    onChange={(e) => setPrenostelOhraniInternet(e.target.checked)}
                  />
                </div>
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <Checkbox
                    className='form-third-width'
                    labelText="E-naslov"
                    id={`prenostel_ohrani_email-${narociloId}`}
                    checked={typeof prenostelOhraniEmail === 'boolean' ? prenostelOhraniEmail : prenostelOhraniEmail === 'true'}
                    onChange={(e) => setPrenostelOhraniEmail(e.target.checked)}
                  />
                  <TextInput
                    className='form-two-thirds-width'
                    labelText="E-naslov"
                    id="prenostel_email"
                    value={prenostelEmail}
                    onChange={(e) => handlePrenostelEmailChange(e.target.value)}
                    placeholder="janez.novak@gmail.com"
                    invalid={!prenostelEmailValid}
                    invalidText="Vnesite veljaven e-mail naslov"
                  />
                </div>
              </div>
              <Checkbox
                labelText="Naročnik je druga oseba"
                id={`prenostel_druga_oseba-${narociloId}`}
                checked={typeof drugNarocnik === 'boolean' ? drugNarocnik : drugNarocnik === 'true'}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
              />
              {drugNarocnik && (
                <>
                  <div className='form-row'>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Ime in priimek/naziv podjetja"
                        id="prenostel_ime_podjetje"
                        value={prenostelImePodjetje}
                        onChange={(e) => setPrenostelImePodjetje(e.target.value)}
                      />
                    </div>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Naslov stalnega bivališča/sedež podjetja"
                        id="prenostel_naslov"
                        value={prenostelNaslov}
                        onChange={(e) => setPrenostelNaslov(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        className="form-third-width"
                        labelText="Poštna št."
                        id="prenostel_postna_st"
                        value={prenostelPostnaSt}
                        onChange={(e) => setPrenostelPostnaSt(e.target.value)}
                      />
                      <TextInput
                        className="form-two-thirds-width"
                        labelText="Kraj"
                        id="prenostel_kraj"
                        value={prenostelKraj}
                        onChange={(e) => setPrenostelKraj(e.target.value)}
                      />
                    </div>
                    <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                      <TextInput
                        labelText="Davčna številka"
                        id="prenostel_davcna"
                        value={prenostelDavcna}
                        onChange={(e) => handlePrenosDavcnaChange(e.target.value)}
                        invalid={!prenostelDavcnaValid}
                        invalidText="Vnesite veljavno davčno številko"
                      />
                    </div>
                  </div>
                </>
              )}

            </Form>
          )}
          {currentForm === 3 && (
            <Form>
              <h2 className="section-title">Način pošiljanja računov</h2>
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <Checkbox
                    className='form-third-width'
                    labelText="E-mail račun"
                    id={`racun-email-${narociloId}`}
                    checked={typeof racunEmail === 'boolean' ? racunEmail : racunEmail === 'true'}
                    onChange={(e) => {
                      setRacunEmail(e.target.checked)
                      if (!e.target.checked) {
                        setRacunEmailNaslov('');
                      } else {
                        setRacunEmailNaslov(email);
                      }
                    }
                    }
                  />
                  <TextInput
                    className='form-two-thirds-width'
                    labelText="E-mail naslov za račun"
                    id="racun-email-naslov"
                    value={racunEmailNaslov}
                    onChange={(e) => handleRacunEmailNaslovChange(e.target.value)}
                    placeholder="janez.novak@gmail.com"
                    invalid={!racunEmailNaslovValid}
                    invalidText="Vnesite veljaven e-mail naslov"
                  />
                </div>
                <div className="form-half-width">
                  <Checkbox
                    labelText="SMS račun"
                    id={`racun-sms-${narociloId}`}
                    checked={typeof racunSMS === 'boolean' ? racunSMS : racunSMS === 'true'}
                    onChange={(e) => setRacunSMS(e.target.checked)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <Checkbox
                    className='form-third-width'
                    labelText="Račun po pošti"
                    id={`racun-pisno-${narociloId}`}
                    checked={typeof racunPisno === 'boolean' ? racunPisno : racunPisno === 'true'}
                    onChange={(e) => {
                      setRacunPisno(e.target.checked)
                      if (!e.target.checked) {
                        setRacunPisnoUlica('');
                        setRacunPisnoPostnaSt('');
                        setRacunPisnoMesto('');
                      } else {
                        setRacunPisnoUlica(`${street || ''} ${hisnaStevilka || ''}`);
                        setRacunPisnoPostnaSt(postalCode || '');
                        setRacunPisnoMesto(city || '');
                      }
                    }
                    }
                  />
                  <TextInput
                    className='form-two-thirds-width'
                    labelText="Ulica, hišna številka"
                    id="racun-pisno-ulica"
                    value={racunPisnoUlica}
                    onChange={(e) => setRacunPisnoUlica(e.target.value)}
                    placeholder="Ulica 1"
                  />
                </div>
                <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                  <TextInput
                    className='form-third-width'
                    labelText="Poštna št."
                    id="racun-pisno-postna-st"
                    value={racunPisnoPostnaSt}
                    onChange={(e) => setRacunPisnoPostnaSt(e.target.value)}
                    placeholder="1000"
                  />
                  <TextInput
                    className='form-two-thirds-width'
                    labelText="Mesto"
                    id="racun-pisno-mesto"
                    value={racunPisnoMesto}
                    onChange={(e) => setRacunPisnoMesto(e.target.value)}
                    placeholder="Ljubljana"
                  />
                </div>
              </div>
            </Form>
          )}
          {currentForm === 4 && (
            <Form>
              <Button kind="primary" renderIcon={DocumentAdd} onClick={handleButtonModalOpen}>
                Priloži
              </Button>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <DataTable
                  rows={attachments.map((attachment, index) => ({
                    id: index.toString(),
                    attachment: attachment,
                  }))}
                  headers={[
                    {
                      key: 'attachment',
                      header: 'Priloge',
                    },
                  ]}
                >
                  {({ rows, headers, getHeaderProps, getRowProps }) => (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {headers.map((header) => (
                              <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.id} {...getRowProps({ row })}>
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>{cell.value}</TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DataTable>
              </div>
            </Form>
          )}
          {currentForm === 5 && documentData && (
            <GDPRForm
              id={`gdpr_form-${narociloId}`}
              narociloId={narociloId}
              key={narociloId}
              checkboxes={gdprConsent}
              setCheckboxes={setGdprConsent}
              gdprChanged={gdprChanged}
              setGdprChanged={setGdprChanged} />
          )}
          {currentForm === 6 && documentData && (
            <DocumentSignPage
              narociloId={narociloId}
              documents={documentData}
              onComplete={(signedDocs) => handleCompleteSignature(signedDocs)}
              toEmails={[email]}
              handleGdprSigned={handleGdprSigned}
            />
          )}
          {currentForm === 7 && (
            <Form>
              <h2>Izberi prejemnike</h2>
              <Checkbox
                labelText="Pošlji tudi stranki"
                id="send-to-client"
                checked={sendToClient}
                onChange={(e) => setSendToClient(e.target.checked)}
              />
              <Button onClick={handleSendDocuments} disabled={isDocsLoading}>
                {isDocsLoading ? 'Pošiljanje ... ' : 'Pošlji dokumente'}
                {isDocsLoading && (
                  <Loading
                    description="Pošiljanje dokumentov"
                    withOverlay={false}
                    small
                  />
                )}
              </Button>

            </Form>
          )}
          {currentForm === 8 && (
            <Form>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <h2>Končano</h2>
                <p>Naročilo je zaključeno</p>
              </div>
            </Form>
          )}
          <div className='button-container-end'>
            <Button kind='primary' renderIcon={Close} onClick={handleCancel}>Zapri</Button>
            <Button kind='primary' renderIcon={ChevronRight} onClick={handleNext} disabled={currentForm === 6 || currentForm === 8 || (currentForm === 7 && !isDocumentSent)} // Update disabled logic
            >Naprej</Button>
          </div>
        </div>

        {isButtonModalOpen && (
          <Modal
            open={isButtonModalOpen}
            onRequestClose={() => setIsButtonModalOpen(false)}
            modalHeading="Dokument za prilogo"
            primaryButtonText="Priloži"
            secondaryButtonText="Prekliči"
            onSecondarySubmit={() => setIsButtonModalOpen(false)}
            onRequestSubmit={handleSubmitPhoto}
            primaryButtonDisabled={!photo}
          >
            <CameraComponent onCapture={handleCapture} />

          </Modal>
        )}

        {!disableMicrophone && (
          <Button
            renderIcon={(props) =>
              isListening
                ? <MicrophoneFilled size={32} {...props} />
                : <Microphone size={32} {...props} />
            }
            iconDescription={isListening ? "Stop recording" : "Start recording"}
            hasIconOnly
            onClick={toggleListening}
            className={`floating-microphone-button ${isListening ? 'recording' : ''}`}
            style={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              borderRadius: '50%',
              minHeight: 'auto',
              width: '4.5rem',
              height: '4.5rem',
              backgroundColor: isListening
                ? 'rgba(218, 30, 40, 0.8)'   // Red with 80% opacity
                : 'rgba(15, 98, 254, 0.8)',  // Blue with 80% opacity
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
            }}
          />
        )}
        {recordingDebug && transcript && (
          <div className="transcript-container">
            <Tag type="blue" title="Transcript">
              {transcript}
            </Tag>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderForm;