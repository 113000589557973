import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Loading,
  Checkbox,
} from '@carbon/react';
import { Copy, CheckmarkFilled, CheckmarkOutline, CloseOutline } from '@carbon/icons-react';

const AdminOrderView = () => {
  const { orderId } = useParams();
  const { pb, isAuthenticated } = useAuth();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastCopiedField, setLastCopiedField] = useState(null);
  
  const copyToClipboard = (text, fieldId) => {
    // Convert text to uppercase before copying
    const uppercaseText = text.toString().toUpperCase();
    navigator.clipboard.writeText(uppercaseText)
      .then(() => {
        setLastCopiedField(fieldId);
        setTimeout(() => {
          setLastCopiedField(null);
        }, 2000);
      })
      .catch(err => {
        console.error('Napaka pri kopiranju:', err);
      });
  };

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const record = await pb.collection('narocilo').getOne(orderId, {
          expand: 'stranka,tehnologija,paket_rel,akcija_rel,narocilododatnipaketi_via_narocilo.paket,narocilododatnipaketi_via_narocilo.akcija'
        });
        setOrderData(record);
      } catch (error) {
        console.error('Error fetching order data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && orderId) {
      fetchOrderData();
    }
  }, [orderId, isAuthenticated, pb]);

  if (!isAuthenticated) {
    return <div>Prosimo, prijavite se za ogled tega naročila.</div>;
  }

  if (loading) {
    return <Loading description="Nalaganje podatkov naročila..." />;
  }

  if (!orderData) {
    return <div>Naročilo ni bilo najdeno.</div>;
  }

  // print orderData
  console.log(orderData);
  
  // Function to get additional packages and equipment
  const getAdditionalPackages = () => {
    if (!orderData?.expand?.narocilododatnipaketi_via_narocilo) return '';
    
    return orderData.expand.narocilododatnipaketi_via_narocilo
      .filter(item => item.tip === 'additional')
      .map(item => {
        const paketName = item.expand?.paket?.paket || '';
        const akcijaPopust = item.expand?.akcija?.popust || '';
        return `${paketName}${akcijaPopust ? ` (${akcijaPopust})` : ''}`;
      })
      .join(', ');
  };

  const getEquipment = () => {
    if (!orderData?.expand?.narocilododatnipaketi_via_narocilo) return '';
    
    return orderData.expand.narocilododatnipaketi_via_narocilo
      .filter(item => item.tip === 'opremafix')
      .map(item => {
        const paketName = item.expand?.paket?.paket || '';
        const akcijaPopust = item.expand?.akcija?.popust || '';
        return `${paketName}${akcijaPopust ? ` (${akcijaPopust})` : ''}`;
      })
      .join(', ');
  };

  // Function to render boolean values with icons
  const renderBooleanValue = (value) => {
    if (value === true) {
      return <CheckmarkOutline style={{ color: '#24a148', verticalAlign: 'middle' }} />;
    } else if (value === false) {
      return <CloseOutline style={{ color: '#525252', verticalAlign: 'middle' }} />;
    }
    return '-';
  };

  // Function to get formatted value for display
  const getFormattedValue = (item) => {
    // Check if the value is a boolean or a string representation of boolean
    if (typeof item.value === 'boolean') {
      return renderBooleanValue(item.value);
    } else if (item.value === 'Da') {
      return renderBooleanValue(true);
    } else if (item.value === 'Ne') {
      return renderBooleanValue(false);
    }
    
    // Return the original value for non-boolean fields
    // Check specifically for 0 values to display them properly
    return item.value !== undefined && item.value !== null ? item.value : '-';
  };

  // Function to check if a value is boolean-like (true/false or "Da"/"Ne")
  const isBooleanValue = (value) => {
    return typeof value === 'boolean' || value === 'Da' || value === 'Ne';
  };

  const orderFields = [
    { header: 'Osnovne informacije', items: [
      { label: 'Ime', value: orderData.ime },
      { label: 'Priimek', value: orderData.priimek },
      { label: 'Davčna številka', value: orderData.davcna_st },
      { label: 'Kontaktni telefon', value: orderData.kontakt_tel },
      { label: 'E-pošta', value: orderData.email },
      { label: 'Podjetje', value: orderData.podjetje },
      { label: 'Zavezanec za DDV', value: orderData.zavezanec_za_ddv ? 'Da' : 'Ne' },
      { label: 'Matična številka podjetja', value: orderData.maticna_st_podjetje },
    ]},
    { header: 'Podatki o naslovu', items: [
      { label: 'Ulica', value: orderData.ulica },
      { label: 'Hišna številka', value: orderData.hisna_st },
      { label: 'Poštna številka', value: orderData.postna_st },
      { label: 'Mesto', value: orderData.posta },
      { label: 'Nadstropje/Stanovanje', value: orderData.nadstropje_in_st_stanovanja },
      { label: 'Naselje', value: orderData.naselje },
    ]},
    { header: 'Podatki o paketu', items: [
      { label: 'Paket', value: orderData.expand?.paket_rel?.paket },
      { label: 'Tip paketa', value: orderData.expand?.paket_rel?.tip },
      { label: 'Cena paketa', value: orderData.cena_paketa_storitev },
      { label: 'Popust', value: orderData.expand?.akcija_rel?.popust },
      { label: 'Vezava (meseci)', value: orderData.popust_vezava },
      { label: 'Tehnologija', value: orderData.expand?.tehnologija?.naziv },
      { label: 'Mobilni paket', value: orderData.mobilni_paket },
      { label: 'eSIM', value: orderData.esim ? 'Da' : 'Ne' },
    ]},
    { header: 'Dodatne storitve', items: [
      { label: 'Paket dodatnih storitev', value: getAdditionalPackages() },
      { label: 'Oprema', value: getEquipment() },
      { label: 'Dodatne storitve interneta', value: orderData.dodatne_storitve_interneta },
      { label: 'Dodatne storitve telefonije', value: orderData.dodatne_storitve_telefonije },
      { label: 'Priključnina', value: orderData.is_prikljucnina ? orderData.prikljucnina : 'Ne' },
      { label: 'Ugodnost ob vezavi', value: orderData.ugodnost_ob_vezavi },
      { label: 'Predčasna aktivacija', value: orderData.predcasna_aktivacija ? 'Da' : 'Ne' },
    ]},
    { header: 'Podatki o plačilu', items: [
      { label: 'SMS račun', value: orderData.racun_sms ? 'Da' : 'Ne' },
      { label: 'E-poštni račun', value: orderData.racun_email ? 'Da' : 'Ne' },
      { label: 'E-pošta za račun', value: orderData.racun_email_naslov },
      { label: 'Bančni račun', value: orderData.racun_banka_iban },
      { label: 'Portal račun', value: orderData.racun_portal ? 'Da' : 'Ne' },
      { label: 'Pisni račun', value: orderData.racun_pisno ? 'Da' : 'Ne' },
      { label: 'Naslov za pisni račun', value: orderData.racun_pisno_ulica },
      { label: 'Poštna št. za pisni račun', value: orderData.racun_pisno_postna_st },
      { label: 'Mesto za pisni račun', value: orderData.racun_pisno_mesto },
      { label: 'Plačilo s trajnikom', value: orderData.placilo_s_trajnikom ? 'Da' : 'Ne' },
    ]},
    { header: 'Prenos številke', items: [
      { label: 'Prenos telefonske številke', value: orderData.prenos_telefonske ? 'Da' : 'Ne' },
      { label: 'Številka za prenos', value: orderData.st_za_prenos },
      { label: 'Telefonska številka za prenos', value: orderData.prenos_telefonska_st },
      { label: 'Operater donor', value: orderData.operater_donor },
      { label: 'Številka računa donorja/PUK', value: orderData.st_racuna_donorja_ali_puk },
      { label: 'Naročnik je druga oseba', value: orderData.narocnik ? 'Da' : 'Ne' },
      { label: 'Ime naročnika', value: orderData.prenos_ime },
      { label: 'Naslov naročnika', value: orderData.prenos_naslov },
      { label: 'Poštna št. in kraj naročnika', value: orderData.prenos_postna_kraj },
      { label: 'Davčna št. naročnika', value: orderData.prenos_davcna },
      { label: 'Soglasje za prenos', value: orderData.prenos_stevilke_soglasje ? 'Da' : 'Ne' },
      { label: 'Soglasje za prekinitev', value: orderData.soglasje_za_prekinitev ? 'Da' : 'Ne' },
    ]},
    { header: 'Dodatni podatki', items: [
      { label: 'Opombe', value: orderData.opombe },
      { label: 'Osebni dokument', value: orderData.osebni_dokument },
    ]},
  ];
  
  return (
    <div className="admin-order-view" style={{ padding: '0 20px' }}>
      <h1>Podrobnosti naročila</h1>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }}>
        {orderFields.map((section, sectionIndex) => (
          <div key={sectionIndex} className="order-section" style={{ flex: '1 1 450px', maxWidth: '600px', marginBottom: '20px' }}>
            <h2>{section.header}</h2>
            <TableContainer>
              <Table size="sm">
                <TableHead>
                  <TableRow>
                    <TableHeader style={{ width: '40%' }}>Polje</TableHeader>
                    <TableHeader style={{ width: '50%' }}>Vrednost</TableHeader>
                    <TableHeader style={{ width: '10%' }}></TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.items.map((item, itemIndex) => (
                    <TableRow key={itemIndex}>
                      <TableCell>{item.label}</TableCell>
                      <TableCell style={lastCopiedField === `${sectionIndex}-${itemIndex}` ? { backgroundColor: '#defbe6' } : {}}>
                        {getFormattedValue(item)}
                        {lastCopiedField === `${sectionIndex}-${itemIndex}` && (
                          <CheckmarkFilled 
                            style={{ 
                              color: '#24a148',
                              marginLeft: '8px',
                              animation: 'fadeIn 0.3s ease-in-out',
                              verticalAlign: 'middle'
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ width: '50px', textAlign: 'center' }}>
                        {(item.value !== undefined && item.value !== null && item.value !== '' && !isBooleanValue(item.value)) && (
                          <Copy 
                            style={{ 
                              cursor: 'pointer', 
                              color: '#0f62fe', 
                              padding: '4px', 
                              borderRadius: '4px', 
                              transition: 'all 70ms cubic-bezier(0.2, 0, 0.38, 0.9)',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '24px',
                              height: '24px',
                              ':hover': {
                                backgroundColor: '#e8e8e8'
                              }
                            }}
                            onClick={() => copyToClipboard(item.value, `${sectionIndex}-${itemIndex}`)}
                            title="Kopiraj v odložišče"
                            className="copy-icon-button"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminOrderView;