import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { ComboBox, MultiSelect, TextInput, Button, ToastNotification, FileUploader, ProgressBar } from '@carbon/react';
import { read, utils, write } from 'xlsx'; 
import fetchDataUsers from '../../components/TerenTable/fetchDataUsers';
import fetchDataPlans from '../../components/TerenTable/fetchDataPlans';
import './CSS/TerenImport.scss';

const TerenImport = () => {
  const { pb } = useAuth();
  
  // Agents list and selection (using multi-select)
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  
  // For backward compatibility (if a single agent is selected and you want to use an existing plan)
  const [selectedPlan, setSelectedPlan] = useState(null);
  // For storing newly created plans when multiple agents are selected
  const [createdPlans, setCreatedPlans] = useState([]);

  const [plans, setPlans] = useState([]);
  const [isCreatingPlan, setIsCreatingPlan] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');
  const [newPlanDate, setNewPlanDate] = useState(null);

  const [files, setFiles] = useState(null);
  const [importMessage, setImportMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [totalToImport, setTotalToImport] = useState(0);
  const [importedCount, setImportedCount] = useState(0);
  const [isImporting, setIsImporting] = useState(false);

  const [failedRows, setFailedRows] = useState([]);

  // Fetch agents from the backend
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const userResult = await fetchDataUsers(pb);
        setAgents(userResult.data);
      } catch (error) {
        console.error('Napaka pri pridobivanju agentov:', error);
      }
    };
    fetchAgents();
  }, [pb]);

  // If a single agent is selected, fetch that agent’s existing plans
  useEffect(() => {
    const fetchAgentPlans = async () => {
      if (selectedAgents.length === 1) {
        try {
          const planResult = await fetchDataPlans(pb, selectedAgents[0].id);
          setPlans(planResult.data);
        } catch (error) {
          console.error('Napaka pri pridobivanju planov:', error);
          setPlans([]);
        }
      } else {
        setPlans([]);
      }
      setSelectedPlan(null);
    };
    fetchAgentPlans();
  }, [selectedAgents, pb]);

  // Create a new plan for each selected agent using the same name/date
  const handleCreatePlan = async () => {
    if (newPlanName.trim() && selectedAgents.length > 0) {
      try {
        const plansCreated = await Promise.all(
          selectedAgents.map(async (agent) => {
            const data = {
              agent: agent.id,
              ime: newPlanName,
              planiran_datum: newPlanDate ? new Date(newPlanDate).toISOString() : null,
            };
            return await pb.collection('plan').create(data);
          })
        );
        setCreatedPlans(plansCreated);
        // If only one agent is selected, also set selectedPlan (for backward compatibility)
        if (plansCreated.length === 1) {
          setSelectedPlan(plansCreated[0]);
        }
        setIsCreatingPlan(false);
        setNewPlanName('');
        setNewPlanDate(null);
      } catch (error) {
        console.error('Napaka pri ustvarjanju plana:', error);
        setImportMessage('Napaka pri ustvarjanju plana: ' + error.message);
        setShowToast(true);
      }
    }
  };

  // File handling – read Excel and parse rows into JSON records
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const data = await file.arrayBuffer();
      const workbook = read(data);
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      const headers = jsonData.shift();
      const records = jsonData
        .filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''))
        .map(row => {
          const record = {};
          headers.forEach((header, index) => {
            record[header] = row[index];
          });
          return record;
        });
      setFiles(records);
    } catch (error) {
      console.error('Napaka pri obdelavi datoteke:', error);
      setImportMessage('Napaka pri obdelavi datoteke: ' + error.message);
      setShowToast(true);
    }
  };

  // Download failed rows as an Excel file
  const downloadFailedRows = () => {
    if (failedRows.length === 0) return;

    const headers = Object.keys(failedRows[0]).concat(['error']);
    const worksheet = utils.json_to_sheet(failedRows, { header: headers });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Not imported');
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'neuspesne_vrstice.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Import teren records: For each row in the file, look up matching teren records and create a record for each plan.
  const importTerenToPlan = async () => {
    // Decide which plan(s) to use:
    // Use the newly created plans (if any) or fall back to the selectedPlan (if using a single agent)
    const plansToUse = createdPlans.length > 0 ? createdPlans : (selectedPlan ? [selectedPlan] : []);

    if (plansToUse.length === 0 || !files) {
      setImportMessage('Izberite plan in datoteko za uvoz.');
      setShowToast(true);
      return;
    }

    setIsImporting(true);
    setTotalToImport(files.length);
    setImportedCount(0);
    setImportMessage('');
    setFailedRows([]);
    
    // Helper functions
    const containsLetters = (str) => /[a-zA-Z]/.test(str);

    const transformStreetName = (streetName) => {
      if (!streetName) return { transformed: '', useLowerOperator: true };
      let useLowerOperator = true;
      const words = streetName.split(' ').map((word) => {
        if (!word) return word;
        const first = word.charAt(0);
        if ('ČŠŽĆ'.includes(first)) {
          //useLowerOperator = false;
          return first + word.slice(1).toLowerCase();
        } else {
          return word.toLowerCase();
        }
      });
      return { transformed: words.join(' '), useLowerOperator };
    };

    // Process a single record from the file.
    // For each matching teren record found in the lookup,
    // loop over each plan and create a record linking that plan and teren.
    const processRecord = async (record) => {
      try {
        const { transformed: transformedStreetName, useLowerOperator } = transformStreetName(record.ulica_naziv);
        const streetNameComparison = useLowerOperator
          ? `ulica_naziv:lower = "${transformedStreetName}"`
          : `ulica_naziv = "${transformedStreetName}"`;

        const houseNumber = record.hs_stevilka?.toString() || '';
        const houseNumberComparison = containsLetters(houseNumber)
          ? `hs_stevilka:lower = "${houseNumber.toLowerCase()}"`
          : `hs_stevilka = "${houseNumber}"`;

        const resultList = await pb.collection('Teren').getList(1, 500, {
          filter: `postni_okolis_sifra = "${record.postni_okolis_sifra}" && ${streetNameComparison} && ${houseNumberComparison}`,
        });

        if (resultList.items.length === 0) {
          throw new Error('Naslov ni najden na GURS.');
        }

        let createdCount = 0;
        for (let terenRecord of resultList.items) {
          // For each found teren, create a record for every plan
          for (let plan of plansToUse) {
            const newRecordData = {
              plan: plan.id,
              teren: terenRecord.id,
            };
            await pb.collection('plan_teren').create(newRecordData);
            createdCount++;
          }
        }
        return createdCount;
      } catch (error) {
        console.error('Napaka pri uvozu terena:', error);
        setFailedRows(prev => [...prev, { ...record, error: error.message }]);
        return 0;
      } finally {
        // Update progress after each record is processed
        setImportedCount(prev => prev + 1);
      }
    };

    const batchSize = 20;
    let totalSuccessCount = 0;

    // Process rows in batches (20 at a time)
    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize);
      const results = await Promise.all(batch.map(record => processRecord(record)));
      totalSuccessCount += results.reduce((acc, curr) => acc + curr, 0);
    }

    setImportMessage(`Uvoz zaključen. Število uvoženih terenov: ${totalSuccessCount}`);
    setShowToast(true);
    setFiles(null);
    setIsImporting(false);
  };

  return (
    <div className="plan-import-app">
      <h2>Uvoz Terenov na Plan</h2>
      <br />

      {/* Agent selection (multiple agents allowed) */}
      <div className="section">
        <label>Izberi Agente:</label>
        <MultiSelect
          items={agents}
          itemToString={(item) => (item ? item.name : '')}
          selectedItems={selectedAgents}
          onChange={({ selectedItems }) => setSelectedAgents(selectedItems)}
          placeholder="Izberi agente"
        />
      </div>

      {/* If only one agent is selected, allow selecting an existing plan */}
      {selectedAgents.length === 1 && (
        <div className="section">
          <label>Izberi Plan:</label>
          <ComboBox
            items={plans}
            itemToString={(item) => (item ? item.ime : '')}
            selectedItem={selectedPlan}
            onChange={({ selectedItem }) => setSelectedPlan(selectedItem)}
            placeholder="Izberi plan"
          />
        </div>
      )}

      {/* New plan creation (for one or more agents) */}
      <div className="section">
        <Button onClick={() => setIsCreatingPlan(!isCreatingPlan)}>
          {isCreatingPlan ? 'Prekliči' : 'Ustvari nov plan'}
        </Button>
        {isCreatingPlan && (
          <div className="create-plan">
            <TextInput
              labelText="Ime Plana"
              value={newPlanName}
              onChange={(e) => setNewPlanName(e.target.value)}
              placeholder="Vnesi ime plana"
            />
            <TextInput
              labelText="Datum Plana"
              type="date"
              value={newPlanDate || ''}
              onChange={(e) => setNewPlanDate(e.target.value)}
            />
            <Button onClick={handleCreatePlan}>Shrani Plan</Button>
          </div>
        )}
      </div>

      {/* File uploader and import button */}
      <div className="section">
        <label>Uvozi Excel Datoteko:</label>
        <FileUploader
          labelDescription="Naloži svojo Excel datoteko"
          buttonLabel="Dodaj datoteko"
          accept={['.xlsx', '.xls']}
          onChange={handleFileChange}
        />
        <Button
          onClick={importTerenToPlan}
          disabled={((createdPlans.length === 0 && !selectedPlan) || !files) || isImporting}
        >
          Uvozi Teren
        </Button>
      </div>

      {isImporting && (
        <div className="section">
          <label>Napredek uvoza:</label>
          <ProgressBar
            value={importedCount}
            max={totalToImport}
            className="import-progress-bar"
            description={`${importedCount} od ${totalToImport} uvoženih`}
          />
        </div>
      )}

      {failedRows.length > 0 && (
        <div className="section">
          <Button onClick={downloadFailedRows} kind="danger" size="md">
            Prenesi Neuspešne Vrstice
          </Button>
        </div>
      )}

      {showToast && (
        <ToastNotification
          kind="success"
          title="Uvoz Terenov"
          subtitle={importMessage}
          onClose={() => setShowToast(false)}
          timeout={7000}
        />
      )}
    </div>
  );
};

export default TerenImport;
