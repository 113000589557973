import React, { useState } from 'react';
import { HeaderGlobalAction, ToastNotification } from '@carbon/react';
import { DocumentDownload } from '@carbon/react/icons';
import loggingService from '../../services/LoggingService';

const LoggingButton = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const handleLogsAction = async () => {
    try {
      // Save logs to PocketBase
      const success = await loggingService.saveToPocketBase();
      
      if (success) {
        setToastMessage('Logs sent successfully');
        setToastType('success');
      } else {
        setToastMessage('Failed to save logs to PocketBase. Downloading locally instead.');
        setToastType('error');
        loggingService.downloadLogs();
      }
      
      setShowToast(true);
      setTimeout(() => setShowToast(false), 4000);
    } catch (error) {
      setToastMessage('Error processing logs');
      setToastType('error');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  return (
    <>
      <HeaderGlobalAction
        aria-label="Save Logs"
        tooltipAlignment="center"
        onClick={handleLogsAction}
      >
        <DocumentDownload size={20} />
      </HeaderGlobalAction>

      {showToast && (
        <ToastNotification
          kind={toastType}
          title={toastType === 'success' ? 'Success' : 'Error'}
          subtitle={toastMessage}
          timeout={3000}
          style={{
            position: 'fixed',
            top: '60px',
            right: '20px',
            zIndex: 9999
          }}
        />
      )}
    </>
  );
};

export default LoggingButton;